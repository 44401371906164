import { IDealerConfiguration, IDefaultTermsInfo } from "../../shared/models/dealer-configuration.model";
import * as fromActions from "./dealer-configuration.actions";
import produce from "immer";
import { denormalizePPCompatibilityMatrix, mapDealerConfiguration } from "./dealer-configuration.adapter";
import { IDealerDefinedCampaign } from "../../shared/models/dealer-defined-campaigns.model";
import { IDealerFeatureConfig } from "../../shared/models/rti/rti-feature-config.model";
import {
  IBankOffer,
  ICreditTier,
  IPPCompatibilityMutexMatrix,
  IPaymentGridInfo, IGPConfig
} from "../../shared/models/bank-configuration.model";
import { ICampaignOffer, IPpPacLimits, IVehiclePacLimits } from "../../shared/models/system-configuration.model";
import { OfferTypes } from "../../shared/enums/offer-types.enum";
import { SalesClasses } from "../../shared/enums/sales-classes.enum";
import { IBrand } from "../../shared/models/brand.model";
import { brandList } from "../../../mocks/brands";

export interface DealerConfigurationState {
  dealerConfiguration: IDealerConfiguration;
  creditTier: ICreditTier[];
  paymentGrid: IPaymentGridInfo;
  grossProfit: IGPConfig;
  ppCompatibility: IPPCompatibilityMutexMatrix[];
  offerConfig: IBankOffer;
  campaignOffer: ICampaignOffer,
  vehiclePacLimits: IVehiclePacLimits,
  protectionProductPacLimits: IPpPacLimits,
  dealerDefinedCampaignsConfiguration: IDealerDefinedCampaign[];
  updatedDdCampaignsState: IDealerDefinedCampaign[];
  dealerFeatureConfig: IDealerFeatureConfig[];
  dealerFeatureConfigError: string;
  isDraftState: boolean;
  salesClasses: string[];
  offerTypes: string[];
  loading: boolean;
  loadingBankConfig: boolean;
  loadingCampaigns: boolean;
  bankConfigLoaded: boolean;
  loadingSystemConfig: boolean;
  systemConfigLoaded: boolean;
  error: string;
  saved: boolean;
  brandList: IBrand[];
}

export const initialState: DealerConfigurationState = {
  isDraftState: true,
  salesClasses: [SalesClasses.NEW, SalesClasses.CERTIFIED, SalesClasses.USED],
  offerTypes: [OfferTypes.LEASE, OfferTypes.FINANCE, OfferTypes.CASH],
  brandList,
} as DealerConfigurationState;

export function dealerConfigurationReducer(
  state: DealerConfigurationState = initialState,
  action: fromActions.DealerConfigurationActions
): DealerConfigurationState {
  switch (action.type) {
    case fromActions.DealerConfigurationActionTypes.LOAD_DEALER_CONFIGURATION: {
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.error = "";
      });
    }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_DEALER_CONFIGURATION_SUCCESS: {
        return produce(state, (draftState) => {
          draftState.dealerConfiguration =
            action instanceof fromActions.LoadDealerConfigurationSuccess
              ? mapDealerConfiguration(action.payload.dealerConfiguration)
              : ({} as IDealerConfiguration);
          draftState.loading = false;
          draftState.error = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_DEALER_CONFIGURATION_FAILURE: {
        return produce(state, (draftState) => {
          draftState.dealerConfiguration =
            action instanceof fromActions.LoadDealerConfigurationFailure
              ? action.payload.dealerConfiguration
              : draftState.dealerConfiguration;
          draftState.loading = false;
          draftState.error =
            action instanceof fromActions.LoadDealerConfigurationFailure
              ? action.payload.error
              : "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .UPDATE_DEALER_CONFIGURATION: {
        return produce(state, (draftState) => {
          draftState.loading = true;
          draftState.error = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .UPDATE_DEALER_CONFIGURATION_SUCCESS: {
        return produce(state, (draftState) => {
          const payload =
            action instanceof fromActions.UpdateDealerConfigurationSuccess
              ? action.payload
              : null;
          draftState.dealerConfiguration = payload.dealerConfiguration;
          draftState.loading = false;
          draftState.error = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .UPDATE_DEALER_CONFIGURATION_FAILURE: {
        return produce(state, (draftState) => {
          draftState.dealerConfiguration =
            action instanceof fromActions.UpdateDealerConfigurationFailure
              ? action.payload.dealerConfiguration
              : draftState.dealerConfiguration;
          draftState.loading = false;
          draftState.error =
            action instanceof fromActions.UpdateDealerConfigurationFailure
              ? action.payload.error
              : "";
        });
      }

    case fromActions.DealerConfigurationActionTypes
      .LOAD_DEALER_FEATURE_CONFIGURATION: {
        return produce(state, (draftState) => {
          draftState.dealerFeatureConfig = undefined;
          draftState.loading = true;
          draftState.dealerFeatureConfigError = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_DEALER_FEATURE_CONFIGURATION_SUCCESS: {
        return produce(state, (draftState) => {
          draftState.dealerFeatureConfig =
            action instanceof fromActions.LoadDealerFeatureConfigurationSuccess
              ? action.payload.dealerFeatureConfig
              : undefined;
          draftState.loading = false;
          draftState.dealerFeatureConfigError = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_DEALER_FEATURE_CONFIGURATION_FAILURE: {
        return produce(state, (draftState) => {
          draftState.dealerFeatureConfig = undefined;
          draftState.loading = false;
          draftState.dealerFeatureConfigError =
            action instanceof fromActions.LoadDealerFeatureConfigurationFailure
              ? action.payload.error
              : "";
        });
      }

    case fromActions.DealerConfigurationActionTypes.LOAD_BANK_CONFIGURATION: {
      return produce(state, (draftState) => {
        draftState.creditTier = undefined;
        draftState.paymentGrid = undefined;
        draftState.grossProfit = undefined;
        draftState.offerConfig = undefined;
        draftState.ppCompatibility = [];
        draftState.bankConfigLoaded = false;
        draftState.loadingBankConfig = true;
        draftState.error = "";
      });
    }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_BANK_CONFIGURATION_SUCCESS: {
        return produce(state, (draftState) => {
          draftState.creditTier =
            action instanceof fromActions.LoadBankConfigurationSuccess
              ? action.payload.bankConfiguration.config.tier
              : [];
          draftState.paymentGrid =
            action instanceof fromActions.LoadBankConfigurationSuccess
              ? action.payload.bankConfiguration.config.paymentGrid
              : undefined;
          draftState.ppCompatibility =
            action instanceof fromActions.LoadBankConfigurationSuccess
              ? denormalizePPCompatibilityMatrix(action.payload.bankConfiguration.config.ppCompatibility)
              : [];
          draftState.offerConfig =
            action instanceof fromActions.LoadBankConfigurationSuccess
              ? action.payload.bankConfiguration.config.offer
              : undefined;
          draftState.grossProfit =
            action instanceof fromActions.LoadBankConfigurationSuccess
              ? action.payload.bankConfiguration.config.grossProfit
              : undefined;
          draftState.bankConfigLoaded = true;
          draftState.loadingBankConfig = false;
          draftState.error = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_BANK_CONFIGURATION_FAILURE: {
        return produce(state, (draftState) => {
          draftState.creditTier = undefined;
          draftState.paymentGrid = undefined;
          draftState.offerConfig = undefined;
          draftState.grossProfit = undefined;
          draftState.loadingBankConfig = false;
          draftState.bankConfigLoaded = false;
          draftState.error =
            action instanceof fromActions.LoadBankConfigurationFailure
              ? action.payload.error
              : "";
        });
      }
    case fromActions.DealerConfigurationActionTypes.LOAD_SYSTEM_CONFIGURATION: {
      return produce(state, (draftState) => {
        draftState.campaignOffer = undefined;
        draftState.systemConfigLoaded = false;
        draftState.loadingSystemConfig = true;
        draftState.error = "";
      });
    }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_SYSTEM_CONFIGURATION_SUCCESS: {
        return produce(state, (draftState) => {
          draftState.campaignOffer =
            action instanceof fromActions.LoadSystemConfigurationSuccess
              ? action.payload.systemConfiguration.config.campaignOffer
              : undefined;
          draftState.vehiclePacLimits = action instanceof fromActions.LoadSystemConfigurationSuccess
          ? action.payload.systemConfiguration.config.vehiclePac
            : undefined;
            draftState.protectionProductPacLimits = action instanceof fromActions.LoadSystemConfigurationSuccess
          ? action.payload.systemConfiguration.config.protectionProductPac
            : undefined;
          draftState.systemConfigLoaded = true;
          draftState.loadingSystemConfig = false;
          draftState.error = "";
        });
      }
    case fromActions.DealerConfigurationActionTypes
      .LOAD_SYSTEM_CONFIGURATION_FAILURE: {
        return produce(state, (draftState) => {
          draftState.campaignOffer = undefined;
          draftState.loadingSystemConfig = false;
          draftState.systemConfigLoaded = false;
          draftState.error =
            action instanceof fromActions.LoadSystemConfigurationFailure
              ? action.payload.error
              : "";
        });
      }
    case fromActions.DealerConfigurationActionTypes.SET_DRAFT_STATUS: {
      return produce(state, (draftState) => {
        draftState.isDraftState =
          action instanceof fromActions.SetDraftStatus
            ? action.payload.draftState
            : draftState.isDraftState;
      });
    }
    default:
      return state;
  }
}
