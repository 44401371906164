import { GrossProfitTypes } from './../../../shared/enums/gp-types.enum';
import { ConfirmDialogComponent } from './../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProtectionProductsState } from './../../../store/protection-products/protection-products.reducer';
import { Store } from '@ngrx/store';
import { ISaveProducts, IPreferenceOrder } from './../../../shared/models/protection-products.model';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormArray, AbstractControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProtectionProductsComponentService } from '../protection-products.component.service';
import * as protectionProductsActions from '../../../store/protection-products/protection-products.actions';
import { SubscriptionList, unsubscribeSubscriptions } from '../../../../components/shared/services/util.service';
import * as _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { selectDisableActions } from './../../../store/dealer-configuration/dealer-configuration.selectors';
import { DealerConfigurationState } from './../../../store/dealer-configuration/dealer-configuration.reducer';
import { selectHasPennProviders, selectMaxVisiblePP } from '../../../store/protection-products/protection-products.selectors';
@Component({
  selector: 'app-protection-products-table',
  templateUrl: './protection-products-table.component.html',
  styleUrls: ['./protection-products-table.component.scss']
})
export class ProtectionProductsTableComponent implements OnInit, OnDestroy {
  /* istanbul ignore next */
  @Input('isOwnPpType') isOwnPpType: boolean;
  private subs: SubscriptionList = {};
  productsData$: Observable<any>;
  grossProfitTypes = GrossProfitTypes;
  user: IUserProfile;
  dialogRef: any;
  disableActions$: Observable<boolean>;
  hasPennProviders$: Observable<boolean>;

  constructor(
    readonly pPComponentService: ProtectionProductsComponentService,
    private readonly protectionProductsState: Store<ProtectionProductsState>,
    private readonly dialog: MatDialog,
    private readonly _userProfileService: UserProfileService,
    readonly translateService: TranslateService,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
  ) { }

  ngOnInit(): void {
    this.user = this._userProfileService.getProfile();
    this.disableActions$ = this.dealerConfigurationState.select(selectDisableActions);
    this.hasPennProviders$ = this.protectionProductsState.select(selectHasPennProviders);
  }

  /* istanbul ignore next */
  drop(event: CdkDragDrop<string[]>): void {
    const ownCnt = this.isOwnPpType ? 0 : this.pPComponentService.ownPpCount;
    moveItemInArray((this.pPComponentService.protectionProductsForm.get('products') as FormArray).controls, event.previousIndex + ownCnt, event.currentIndex + ownCnt);
    (this.pPComponentService.protectionProductsForm.get('products') as FormArray).controls.forEach((product: AbstractControl, index) => {
      product.get(['global', 'order']).patchValue(index);
    });
    this.pPComponentService.protectionProductsForm.updateValueAndValidity();
    this.pPComponentService.updateOrderChangeDetector();
  }


  /* istanbul ignore next */
  setProductSelection(productId: string) {


    this.pPComponentService.getChangeOrder$
      .pipe(take(1)).subscribe(canChangeOrder => {
        if (!(canChangeOrder || this.pPComponentService.addingProduct.value)) {
          if (productId != this.pPComponentService.selectedProductData?.id) {
            this.pPComponentService.selectedProductConfig$.pipe(take(1)).subscribe(productConfig => {
              const productDetailsForm = (this.pPComponentService.protectionProductsForm.get('products') as FormArray)
                .controls.filter(t => t.getRawValue().id === productConfig?.id)[0] as FormGroup;
              if (this.pPComponentService.selectedProductConfigString !== JSON.stringify(productDetailsForm?.getRawValue())) {
                this.revertChanges('Product Selection', productId);
              }
              else {
                this.setProductSelectionConfirmed(productId);
              }
            });
          }
        }
      });

  }

  /* istanbul ignore next */
  setProductSelectionConfirmed(productId: string) {
    this.pPComponentService.setSelectedProduct(productId);
  }

  /* istanbul ignore next */
  editOrderAction(val: boolean) {
    if (val) {
      this.pPComponentService.selectedProductConfig$.pipe(take(1)).subscribe(productConfig => {
        const productDetailsForm = (this.pPComponentService.protectionProductsForm.get('products') as FormArray)
          .controls.filter(t => t.value.id === productConfig.id)[0] as FormGroup;
        if (!(this.pPComponentService.selectedProductConfigString === JSON.stringify(productDetailsForm?.getRawValue()))) {
          this.revertChanges('Edit Order', val);
        }
        else {
          this.editOrderCallback(val);
        }
      });
    }
    else {
      this.pPComponentService.setChangeOrder(val);
      this.pPComponentService.setEditDisabled(val);
    }
  }

  /* istanbul ignore next */
  addProductAction() {
    this.pPComponentService.selectedProductConfig$.pipe(take(1)).subscribe(productConfig => {
      const productDetailsForm = (this.pPComponentService.protectionProductsForm.get('products') as FormArray)
        .controls.filter(t => t.value.id === productConfig.id)[0] as FormGroup;
      if (!(this.pPComponentService.selectedProductConfigString === JSON.stringify(productDetailsForm?.getRawValue()))) {
        this.revertChanges('Add Product', undefined);
      }
      else {
        this.pPComponentService.beginAddNewProduct();
      }
    });
  }

  /* istanbul ignore next */
  editOrderCallback(val: boolean) {
    this.pPComponentService.setChangeOrder(val);
    this.pPComponentService.setEditDisabled(val);
    this.pPComponentService.protectionProductsForm.disable({ emitEvent: false });
  }

  /* istanbul ignore next */
  revertChanges(action: string, data?: any) {
    if (this.pPComponentService.isReadOnly && action === 'Product Selection') {
      this.pPComponentService.triggerSelectedProductReset();
      this.setProductSelectionConfirmed(data);
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { popupTitle: this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.EDIT_CONFIRM_TITLE'), popupBodyText: this.translateService.instant('LOCALIZATION.PROTECTION_PRODUCTS.EDIT_CONFIRM'), cancelButtonText: this.translateService.instant('LOCALIZATION.COMMON.NO'), confirmButtonText: this.translateService.instant('LOCALIZATION.COMMON.YES'), action }
    });
    const afterClosedSub = this.dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'Edit Order') {
        this.pPComponentService.triggerSelectedProductReset();
        this.editOrderCallback(data);
      }
      else if (result?.action === 'Product Selection') {
        this.pPComponentService.triggerSelectedProductReset();
        this.setProductSelectionConfirmed(data);
      }
      else if (result?.action === 'Add Product') {
        this.pPComponentService.triggerSelectedProductReset();
        this.pPComponentService.beginAddNewProduct();
      }
      afterClosedSub.unsubscribe();
    })
  }

  /* istanbul ignore next */
  updateOrder() {
    this.pPComponentService.setChangeOrder(false);
    this.pPComponentService.setEditDisabled(false);

    const preferenceOrder: IPreferenceOrder[] =
      (this.pPComponentService.protectionProductsForm.get('products') as FormArray).controls
        .map((product, index) => {
          return { objectId: product.value.id, preferenceOrder: index, providerId: product.value.providerId } as IPreferenceOrder;
        });

    const orderData: ISaveProducts = {
      masterData: null,
      configurationData: null,
      preferenceOrder
    }
    this.protectionProductsState.dispatch(new protectionProductsActions.UpdateTfsProductsOrder({
      dealerCode: this.pPComponentService.dealerCode, protectionProducts: orderData
    }));
  }


  updateMaxVisibilePPConfirmation(maxVisiblePP: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        popupBodyText: this.translateService.instant(
          'LOCALIZATION.PROTECTION_PRODUCTS.UPDATE_MAX_VISIBLE_PP_CONFIRMATION'
        ),
        confirmButtonText: this.translateService.instant(
          'LOCALIZATION.COMMON.YES'
        )
      },
    });
    const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
      if (result?.action === 'confirm') {
        this.updateMaxVisiblePP(maxVisiblePP);
      }
      else {
        this.protectionProductsState.select(selectMaxVisiblePP).pipe(take(1)).subscribe(oldValue => {
          this.pPComponentService.protectionProductsForm.get(['maxVisiblePP']).patchValue(oldValue, { emitEvent: false });
        });
      }
      afterClosedSub.unsubscribe();
    });
  }

  updateMaxVisiblePP(maxVisiblePP: number) {
    const maxVisiblePPObj: ISaveProducts = {
      masterData: null,
      configurationData: null,
      maxVisiblePP
    }
    this.protectionProductsState.dispatch(new protectionProductsActions.UpdateMaxVisiblePP({
      dealerCode: this.pPComponentService.dealerCode, protectionProducts: maxVisiblePPObj
    }));
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }
}
