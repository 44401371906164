<div class="protection-product-details-container">
    <div [formGroup]="productDetailsForm" *ngIf="productDetailsForm && (pPComponentService.getSelectedProduct$ | async)"
        class="tfs-pp-color">
        <div class="d-flex mr-2 my-2">
            <strong class="my-auto"
                *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId === jmnaProviderId">{{(pPComponentService.getSelectedProduct$
                |
                async)?.code +
                ((pPComponentService.getSelectedProduct$ | async)?.description ? ' - ' +
                (pPComponentService.getSelectedProduct$ | async)?.description :
                '')}}</strong>
            <strong class="my-auto"
                *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId !== jmnaProviderId && !(pPComponentService.getAddingProduct$ | async)">
                {{ productDetailsForm?.controls.global?.controls.productTitle?.value ||
                (pPComponentService.getSelectedProduct$ | async)?.code || 'No Title Set'
                }}</strong>
            <strong class="my-auto" *ngIf="pPComponentService.getAddingProduct$ | async">
                {{'LOCALIZATION.PROTECTION_PRODUCTS.ADD_NEW_PROD_NOTE' | translate}}</strong>
            <input *ngIf="!(pPComponentService.getEditDisabled$ | async) && !(disableActions$ | async)" type="button"
                class="secondary-button small-button ml-3" (click)="resetProductDetails()"
                value="{{'LOCALIZATION.COMMON.RESET' | translate}}" />
            <input *ngIf="!pPComponentService.isReadOnly && !(pPComponentService.getEditDisabled$ | async)"
                type="button" class="primary-button small-button ml-3"
                (click)="saveProductDetails(savePpApiInstruction.UPDATE)"
                value="{{'LOCALIZATION.COMMON.SAVE' | translate}}" />
            <input *ngIf="!pPComponentService.isReadOnly && (pPComponentService.getAddingProduct$ | async)"
                type="button" class="secondary-button small-button ml-3"
                (click)="pPComponentService.cancelAddNewProduct()"
                value="{{'LOCALIZATION.COMMON.CANCEL' | translate}}" />
            <input *ngIf="!pPComponentService.isReadOnly && (pPComponentService.getAddingProduct$ | async) &&
                (productDetailsForm?.controls.global?.controls.productTypes?.value | checkproductdefaultsloaded 
                : productDetailsForm?.controls.global?.controls.provider?.value : (pennProductDefaultsList$ | async))"
                type="button" class="primary-button small-button ml-3"
                (click)="saveProductDetails(savePpApiInstruction.CREATE)"
                value="{{'LOCALIZATION.COMMON.SAVE' | translate}}" />
            <input
                *ngIf="!pPComponentService.isReadOnly && !(pPComponentService.getEditDisabled$ | async) && (pPComponentService.getSelectedProduct$ | async)?.providerId !== pPComponentService.ownPpProviderId"
                type="button" class="primary-button small-button ml-auto" (click)="deleteProduct()"
                value="{{'LOCALIZATION.COMMON.DELETE' | translate}}" />
            <div *ngIf="pPComponentService.isSetDealer && (pPComponentService.getSelectedProduct$ | async)?.providerId === jmnaProviderId"
                formGroupName="global" class="d-flex ml-auto">
                <span>{{'LOCALIZATION.PROTECTION_PRODUCTS.HIDE_CUST_NOTE' | translate}}&nbsp;&nbsp;</span>
                <mat-slide-toggle formControlName="hide" [disabled]="(disableActions$ | async)">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="pos-relative">
            <mat-tab-group animationDuration="0ms" (selectedIndexChange)="selectedDetailsTabIndex = $event"
                [selectedIndex]="selectedDetailsTabIndex">
                <mat-tab
                    [disabled]="!((pPComponentService.getSelectedProduct$ | async)?.providerId !== pPComponentService.ownPpProviderId)"
                    label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_OVERVIEW' | translate}}">
                    <div formGroupName="global" class="overview-container pt-2"
                        *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId !== pPComponentService.ownPpProviderId && showDetails">
                        <div class="row no-gutters">
                            <div class="d-flex flex-column mr-2 justify-content-end">
                                <mat-label class="mb-2 fw-bold">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PROD_TITLE' | translate}}</mat-label>
                                <input class="product-input product-title"
                                    [attr.disabled]="(disableActions$ | async) ? true : null"
                                    [matTooltip]="productDetailsForm.controls.global.controls.productTitle.errors?.productTitleNotUnique ? productDetailsForm.controls.global.controls.productTitle.errors?.productTitleNotUniqueMsg : ''"
                                    [ngClass]="{'input-error': productDetailsForm.controls.global.controls.productTitle.invalid}"
                                    type="text" formControlName="productTitle" maxlength="100" />
                            </div>
                            <div class="d-flex ml-auto mr-2">
                                <div class="d-flex flex-column ml-auto mr-3"
                                    *ngIf="pPComponentService.isSetDealer && (pPComponentService.getAddingProduct$ | async)">
                                    <mat-label class="my-1 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_TAX'
                                        | translate}}</mat-label>
                                    <mat-slide-toggle formControlName="taxable">
                                    </mat-slide-toggle>
                                </div>
                                <div class="d-flex flex-column mr-2"
                                    *ngIf="pPComponentService.getAddingProduct$ | async">
                                    <mat-label class="my-1 fw-bold">
                                        {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_CAPITALIZED' | translate}}
                                    </mat-label>
                                    <mat-slide-toggle formControlName="capitalized">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="d-flex flex-column mr-4">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PROVIDER'
                                    | translate}}</mat-label>
                                <mat-select formControlName="provider" (selectionChange)="providerChange($event.value)"
                                    [ngClass]="{'input-error': productDetailsForm.controls.global.controls.provider.invalid}"
                                    [matTooltip]="productDetailsForm.controls.global.controls.provider.value">
                                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                    <mat-option *ngFor="let provider of (pennProviders$ | async)"
                                        [value]="provider.name">
                                        {{provider.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="d-flex flex-column mr-4">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PRODUCT'
                                    | translate}}</mat-label>
                                <mat-select formControlName="productTypes"
                                    (selectionChange)="productChange($event.value)"
                                    [ngClass]="{'input-error': productDetailsForm.controls.global.controls.productTypes.invalid}"
                                    [matTooltip]="productDetailsForm.controls.global.controls.productTypes.value">
                                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                    <mat-option *ngFor="let product of (productDetailsForm.controls.global.controls.provider?.value
                                            | pennproductsfilter : (pennProviders$ | async))" [value]="product.name"
                                        [disabled]="product.disabled">
                                        {{product.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="d-flex flex-column mr-4" *ngIf="pPComponentService.getAddingProduct$ | async">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_REGULATE'
                                    | translate}}?</mat-label>
                                <input disabled class="product-input"
                                    [value]="(pPComponentService.getSelectedProduct$ | async)?.isRegulated === 'Y' ? 'Yes' : 'No'" />
                            </div>
                            <div class="d-flex flex-column mr-4"
                                *ngIf="(pPComponentService.getSelectedProductConfig$ | async)?.global?.providerId?.toLowerCase() === pennProviderId && productDetailsForm.controls.global.controls.bookCode">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_RBCODE' |
                                    translate}}</mat-label>
                                <ng-container
                                    *ngIf="(productDetailsForm.controls.global.controls.provider?.value | pennratebookcode
                                        : productDetailsForm.controls.global.controls.productTypes?.value : (pennProviders$ | async)) as rateBookCodeList else rateBookDisplay">
                                    <mat-select formControlName="bookCode"
                                        [ngClass]="{'input-error': productDetailsForm.controls.global.controls.bookCode.invalid}"
                                        [matTooltip]="productDetailsForm.controls.global.controls.bookCode.value | ratebookdescription : rateBookCodeList">
                                        <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                        <mat-option *ngFor="let rateBookCode of rateBookCodeList"
                                            [value]="rateBookCode.bookCode" [disabled]="rateBookCode.disabled">
                                            {{rateBookCode.description}}
                                        </mat-option>
                                    </mat-select>
                                </ng-container>
                                <ng-template #rateBookDisplay>
                                    <mat-select formControlName="bookCode"
                                        [ngClass]="{'input-error': productDetailsForm.controls.global.controls.bookCode.invalid}">
                                        <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                    </mat-select>
                                </ng-template>
                            </div>
                            <div class="d-flex flex-column mr-4"
                                *ngIf="(pPComponentService.getSelectedProductConfig$ | async)?.global?.providerId?.toLowerCase() === nontmisProviderId ">
                                <mat-label class="my-2 fw-bold">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_DEALER_COST' | translate}}
                                </mat-label>
                                <div class="pos-relative">
                                    <span class="dollar-symbol">$&nbsp;</span>
                                    <input class="product-input dollar-input no-number-arrow" min="0" numbersOnly
                                        [attr.disabled]="(disableActions$ | async) ? true : null" type="number"
                                        maxlength="5" formControlName="dealerCost">
                                </div>
                            </div>
                            <ng-container formGroupName="dynamicFields" *ngIf="showDynamicFields">
                                <div class="d-flex flex-column mr-4 pos-relative" *ngFor="let field of (productDetailsForm.controls.global.controls.productTypes?.value
                                        | getorupdateproductform : productDetailsForm?.controls.global?.controls.provider?.value 
                                        : (pPComponentService.getSelectedProductConfig$ | async))">
                                    <mat-label class="my-2 fw-bold">{{field.fieldName}}</mat-label>
                                    <input [formControlName]="field.fieldId"
                                        *ngIf="field.fieldType?.toLowerCase() === dynamicFieldTypeEnum.TEXTBOX"
                                        [attr.disabled]="(disableActions$ | async) ? true : null"
                                        class="product-input no-number-arrow" min="0" numbersOnly type="number"
                                        [maxlength]="field.fieldLength?field.fieldLength:10" />
                                    <mat-select [formControlName]="field.fieldId" [disabled]="(disableActions$ | async)"
                                        *ngIf="field.fieldType?.toLowerCase() === dynamicFieldTypeEnum.DROPDOWN">
                                        <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                        <mat-option *ngFor="let opt of field.options" [value]="opt.value">
                                            {{opt.label}}
                                        </mat-option>
                                    </mat-select>
                                    <ng-container
                                        *ngIf="field.fieldType?.toLowerCase() === dynamicFieldTypeEnum.CALENDAR">
                                        <input matInput autocomplete="off" [formControlName]="field.fieldId"
                                            [disabled]="(disableActions$ | async)"
                                            class="product-input date-picker-input" [matDatepicker]="dynamicdatepicker">
                                        <mat-datepicker-toggle disableRipple matSuffix [for]="dynamicdatepicker"
                                            [disabled]="(disableActions$ | async)"
                                            style="position: absolute; top: 25px; left: 110px;">
                                            <mat-icon matDatepickerToggleIcon>
                                                <em class="tmi tmi-date"></em>
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dynamicdatepicker></mat-datepicker>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row no-gutters">
                            <div class="d-flex flex-column mr-4">
                                <mat-label class="my-2 fw-bold">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_TAX_CATEGORY' | translate}}
                                </mat-label>
                                <mat-select formControlName="taxClassificationId" [disabled]="(disableActions$ | async)"
                                    [ngClass]="{'input-error': productDetailsForm.controls.global.controls.taxClassificationId.invalid}">
                                    <mat-option value="">{{'LOCALIZATION.COMMON.SELECT' | translate}}</mat-option>
                                    <mat-option *ngFor="let taxCategory of (taxCategories$ | async)"
                                        [value]="taxCategory.id">
                                        {{taxCategory.description}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div formGroupName="gp" class="d-flex flex-column mr-4"
                                *ngIf="pPComponentService.getAddingProduct$ | async">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_GP' |
                                    translate}}</mat-label>
                                <div class="d-flex gp-input-container">
                                    <input type="number" numbersOnly min="0"
                                        class="product-input gp-input no-number-arrow"
                                        [matTooltip]="productDetailsForm.controls.global.controls.gp.errors?.gpExceedsMaxPrice ? productDetailsForm.controls.global.controls.gp.errors?.gpExceedsMaxPriceMsg : ''"
                                        [ngClass]="{'input-error': productDetailsForm.controls.global.controls.gp.controls.value.invalid || productDetailsForm.controls.global.controls.gp.errors?.gpExceedsMaxPrice}"
                                        formControlName="value"
                                        [attr.disabled]="((pPComponentService.getSelectedProduct$ | async)?.isRegulated === 'Y') ? true : null">
                                    <mat-button-toggle-group class="product-button-toggle ml-1" formControlName="type"
                                        [disabled]="((pPComponentService.getSelectedProduct$ | async)?.isRegulated === 'Y')">
                                        <mat-button-toggle [value]="grossProfitTypes.AMOUNT"
                                            aria-label="Text align left">
                                            $
                                        </mat-button-toggle>
                                        <mat-button-toggle [value]="grossProfitTypes.PERCENT"
                                            aria-label="Text align center">
                                            %
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                            <div class="d-flex flex-column mr-4" *ngIf="pPComponentService.getAddingProduct$ | async">
                                <mat-label class="my-2 fw-bold">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MP' | translate}}<em
                                        class="max-price-info tmi tmi-info ml-1"
                                        *ngIf="(pPComponentService.getSelectedProduct$ | async).providerId !== nontmisProviderId"
                                        matTooltip="{{'LOCALIZATION.PROTECTION_PRODUCTS.MAX_PRICE_TOOLTIP' | translate}}"></em>
                                </mat-label>
                                <div class="pos-relative">
                                    <span class="dollar-symbol">$&nbsp;</span>
                                    <input class="product-input dollar-input no-number-arrow" min="0" numbersOnly
                                        [matTooltip]="productDetailsForm.controls.global.controls.gp.errors?.gpExceedsMaxPrice ? productDetailsForm.controls.global.controls.gp.errors?.gpExceedsMaxPriceMsg : ''"
                                        [ngClass]="{'input-error': productDetailsForm.controls.global.controls.maxPrice.invalid}"
                                        type="number" maxlength="5" formControlName="maxPrice"
                                        [attr.disabled]="((pPComponentService.getSelectedProduct$ | async)?.isRegulated === 'Y') ? true : null">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column"
                            *ngIf="(pPComponentService.getSelectedProductConfig$ | async)?.global?.providerId">
                            <div class="mt-3">
                                <mat-checkbox [checked]="pennProductDetails?.marketingContent?.allContentAvailable"
                                    disabled="true" class="appear-enabled">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.ALL_CONTENT_AVALIABLE' | translate}}
                                </mat-checkbox>
                            </div>
                            <div class="d-flex flex-column">
                                <mat-label class="my-2 fw-bold">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_DESC' | translate}}</mat-label>
                                <textarea formControlName="description" rows="3" maxlength="2000"
                                    [attr.disabled]="(disableActions$ | async) ? true : null" placeholder="Enter notes"
                                    class="w-100 p-2 product-description"
                                    [ngClass]="{'input-error': productDetailsForm?.controls.global?.controls.description?.invalid}"
                                    *ngIf="productDetailsForm?.controls.global?.controls.description"></textarea>
                                <div class="w-100 p-2 product-description"
                                    [innerHTML]="pennProductDetails?.marketingContent?.description"
                                    *ngIf="!productDetailsForm?.controls.global?.controls.description"></div>
                            </div>
                            <div class="d-flex flex-column">
                                <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PROD_IMG'
                                    |
                                    translate}}</mat-label>
                                <div class="d-flex product-image-container"
                                    *ngIf="!pennProductDetails?.marketingContent?.imageUrl"
                                    [ngClass]="{'input-error': productDetailsForm?.controls.global?.controls.imageUrl?.invalid}">
                                    <div class="product-image-preview-container py-2" *ngIf="!!productImageUrl">
                                        <img class="product-image-preview" [src]="productImageUrl"
                                            alt="Product Image" />
                                    </div>
                                    <div *ngIf="!pPComponentService.isReadOnly" class="product-image-upload-container">
                                        <div *ngIf="!productImageUrl"
                                            class="product-image-upload-div d-flex mx-auto my-2"
                                            (click)="productImageInput.click()">
                                            <em class="tmi tmi-upload ml-3"></em>
                                            <div class="product-image-upload flex-column mx-3">
                                                <mat-label class=" fw-bold">
                                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PROD_IMG' |
                                                    translate}}
                                                </mat-label>
                                                <span>{{'LOCALIZATION.PROTECTION_PRODUCTS.PROD_IMG_NOTE_SIZE' |
                                                    translate}}</span>
                                                <span>{{'LOCALIZATION.PROTECTION_PRODUCTS.PROD_IMG_NOTE_ORIENT' |
                                                    translate}}</span>
                                                <span>{{'LOCALIZATION.PROTECTION_PRODUCTS.PROD_IMG_NOTE_ASPECT_RATIO' |
                                                    translate}}</span>
                                            </div>
                                            <input hidden
                                                (change)="onProductImageSelected($event);imageChangedEvent = $event"
                                                #productImageInput type="file" accept="image/*">
                                        </div>
                                        <ng-container *ngIf="!!productImageUrl">
                                            <button class="secondary-button large-button product-image-upload"
                                                (click)="removeProductImage()">{{'LOCALIZATION.PROTECTION_PRODUCTS.PROD_IMG_REMOVE'
                                                | translate}}</button>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-flex product-image-container"
                                    *ngIf="!!pennProductDetails?.marketingContent?.imageUrl">
                                    <div class="product-image-preview-container py-2">
                                        <img class="product-image-preview"
                                            [src]="pennProductDetails.marketingContent.imageUrl | generaterandomqueryparam"
                                            alt="Product Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loader-container" [ngClass]="{'d-none': showDetails}">
                        <dd-spinner></dd-spinner>
                    </div>
                </mat-tab>
                <mat-tab formGroupName="global" label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_DEFAULTS' | translate}}"
                    [disabled]="!(plans.length > 0)" class="pos-relative">
                    <ng-container *ngIf="plans.length > 0">
                        <div *ngIf="!pPComponentService.isReadOnly" class="d-flex mr-2 mt-3 autofill-position">
                            <mat-checkbox (change)="autofill = !autofill"
                                matTooltip="Autofill defaults form for selected offer">
                                {{'LOCALIZATION.PROTECTION_PRODUCTS.AUTOFILL' | translate}}
                            </mat-checkbox>
                        </div>
                        <ng-container formGroupName="defaults">
                            <ng-container *ngFor="let salesClass of (salesClasses$ | async)">
                                <div *ngIf="(pPComponentService.getSelectedProduct$ | async).id | checksalesclassdataexistspipe : salesClass"
                                    class="col-12 mt-2 p-2 vehicle-type-div tfs-pp-color" [formGroupName]="salesClass">
                                    <span
                                        class="vehicle-type-section-title text-cap">{{'LOCALIZATION.PROTECTION_PRODUCTS.DEFAULT_HEADER_NOTE'
                                        | translate:({"salesClass": salesClass})}}</span>
                                    <mat-tab-group class="mt-3" mat-stretch-tabs animationDuration="0ms"
                                        (selectedIndexChange)="selectedOfferTypeTabIndex = $event"
                                        [selectedIndex]="selectedOfferTypeTabIndex">
                                        <ng-container
                                            *ngFor="let offerType of (offerTypes$ | async),let offerIndex = index">
                                            <mat-tab [label]="offerType"
                                                [disabled]="!((pPComponentService.getSelectedProductConfig$ | async)
                                                | getformarray : 'global|defaults|' + salesClass + '|' + offerType).length > 0">
                                                <table aria-describedby="tableHd" *ngIf="showDetails && ((pPComponentService.getSelectedProductConfig$ | async) 
                                            | getformarray : 'global|defaults|' + salesClass + '|' + offerType).length > 0
                                                else noDefaultsMessage" class="table example-full-width mt-3">
                                                    <thead>
                                                        <th scope="col" *ngIf="offerType != 'cash'" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MONTHS' |
                                                            translate}}</th>
                                                        <th scope="col" *ngIf="!hasSingleDefaultForm" class="pl-2"
                                                            style="width: 30%;">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_FORM' |
                                                            translate}}
                                                        </th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_COVERAGE' |
                                                            translate}}</th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_DEDUCTIBLE' |
                                                            translate}}</th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_TERM_MILEAGE'
                                                            | translate}}</th>
                                                    </thead>
                                                    <tbody [formArrayName]="offerType">
                                                        <tr [ngClass]="{'border-bottom': !lastRow}"
                                                            *ngFor="let term of ((pPComponentService.getSelectedProductConfig$ | async)
                                                        | getformarray : 'global|defaults|' + salesClass + '|' + offerType), let i = index, last as lastRow"
                                                            [formGroupName]="i">
                                                            <td *ngIf="offerType != 'cash'">
                                                                <mat-label>
                                                                    {{
                                                                    term.months
                                                                    }}
                                                                </mat-label>
                                                            </td>
                                                            <td *ngIf="!hasSingleDefaultForm">
                                                                <mat-select formControlName="formId"
                                                                    [disabled]="(disableActions$ | async)"
                                                                    style="width: 100% !important;"
                                                                    (selectionChange)="setFormValues('formId',$event.value,term,
                                                                getFormGroupName(salesClass,offerType).controls[i],salesClass,offerType,$event.value)">
                                                                    <mat-option
                                                                        *ngFor="let form of (pPComponentService.getSelectedProduct$ | async).forms"
                                                                        [value]="form.code" [title]="form.description">
                                                                        {{form.description}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </td>
                                                            <td
                                                                *ngIf="(plans | plansfilter : term.formId : salesClass : offerType : term.months) as planList">
                                                                <mat-select [disabled]="(disableActions$ | async)"
                                                                    *ngIf="!(planList.length === 0 || (planList.length === 1 && planList[0].code === '')) else cov"
                                                                    formControlName="planId"
                                                                    (selectionChange)="setFormValues('planId',$event.value,term,
                                                                getFormGroupName(salesClass,offerType).controls[i],salesClass,offerType,
                                                            getFormGroupName(salesClass,offerType).controls[i].value.formId)">
                                                                    <mat-option *ngFor="let plan of planList"
                                                                        [value]="plan.code"
                                                                        [title]="plan.description ? plan.description + ' - ' + plan.code : plan.code">
                                                                        {{plan.description ? plan.description + ' - ' +
                                                                        plan.code : plan.code}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <ng-template #cov>
                                                                    <mat-label class="not-apply">Not Applicable
                                                                    </mat-label>
                                                                </ng-template>
                                                            </td>
                                                            <td
                                                                *ngIf="(term.planId | ppoptionsfilter : term.formId : salesClass : offerType : deductibles) as deductiblesList">
                                                                <mat-select [disabled]="(disableActions$ | async)"
                                                                    *ngIf="deductiblesList.length > 0 else deduct"
                                                                    formControlName="selectedDeductible"
                                                                    (selectionChange)="setFormValues('selectedDeductible',$event.value,term,
                                                                getFormGroupName(salesClass,offerType).controls[i],salesClass,offerType,
                                                            getFormGroupName(salesClass,offerType).controls[i].value.formId,
                                                            getFormGroupName(salesClass,offerType).controls[i].value.planId)">
                                                                    <mat-option
                                                                        *ngFor="let deductible of deductiblesList"
                                                                        [value]="deductible.code"
                                                                        [title]="deductible.description">
                                                                        {{deductible.description}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <ng-template #deduct>
                                                                    <mat-label class="not-apply">Not Applicable
                                                                    </mat-label>
                                                                </ng-template>
                                                            </td>
                                                            <td
                                                                *ngIf="(term.planId | ppoptionsfilter : term.formId : salesClass : offerType : coverageOptions) as covOptsList">
                                                                <mat-select [disabled]="(disableActions$ | async)"
                                                                    *ngIf="covOptsList.length > 0 else coverageOpts"
                                                                    formControlName="selectedTimeMileage"
                                                                    (selectionChange)="setFormValues('selectedTimeMileage',$event.value,term,
                                                                getFormGroupName(salesClass,offerType).controls[i],salesClass,offerType,
                                                            getFormGroupName(salesClass,offerType).controls[i].value.formId,
                                                            getFormGroupName(salesClass,offerType).controls[i].value.planId)">
                                                                    <mat-option
                                                                        *ngFor="let coverageOption of covOptsList"
                                                                        [value]="coverageOption.code"
                                                                        [title]="coverageOption.description">
                                                                        {{coverageOption.description}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <ng-template #coverageOpts>
                                                                    <mat-label class="not-apply">Not Applicable
                                                                    </mat-label>
                                                                </ng-template>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <ng-template #noDefaultsMessage>
                                                    <div class="d-flex">
                                                        <span
                                                            class="d-flex m-auto pt-2 font-semibold">{{'LOCALIZATION.PROTECTION_PRODUCTS.OFFER_VALID_TEXT'
                                                            | translate}}</span>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>
                                        </ng-container>
                                    </mat-tab-group>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <mat-tab formGroupName="global"
                    label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_PRESENTATION' | translate}}"
                    [disabled]="!((pPComponentService.getSelectedProductConfig$ | async)?.global?.plans?.length > 0)">
                    <div *ngIf="(pPComponentService.getSelectedProductConfig$ | async)?.global?.plans?.length > 0"
                        class="p-2 mt-2 plans-container">
                        <table class="table example-full-width mt-3" aria-describedby="tableHd">
                            <thead>
                                <th scope="col"
                                    *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId !== jmnaProviderId"
                                    class="pl-2">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_TYPE' | translate}}</th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_COVERAGE' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_DISPLAY' |
                                    translate}}
                                </th>
                                <th *ngIf="(pPComponentService.getSelectedProduct$ | async).code | checkmutexproduct"
                                    scope="col" class="pl-2">
                                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_INCLUDE_PLAN' |
                                    translate}}
                                </th>
                            </thead>
                            <tbody formArrayName="plans">
                                <tr [ngClass]="{'border-bottom': !lastRow}"
                                    *ngFor="let plan of ((pPComponentService.getSelectedProductConfig$ | async)
                                    | getplansarray : productDetailsForm), let i = index, last as lastRow, first as firstRow" [formGroupName]="i">
                                    <td *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId !== jmnaProviderId"
                                        class="text-cap">
                                        {{ firstRow ? plan.value.salesClass :
                                        (productDetailsForm.controls.global.controls.plans.controls[i].value.salesClass
                                        ==
                                        productDetailsForm.controls.global.controls.plans.controls[i-1].value.salesClass
                                        ? '': plan.value.salesClass) }}
                                    </td>
                                    <td>
                                        <span>{{plan.value.id | plandescription : plan.value.formId : plans :
                                            (pPComponentService.getSelectedProduct$ | async)?.providerId}}</span>
                                    </td>
                                    <td class="d-flex">
                                        <mat-slide-toggle formControlName="visible"
                                            [disabled]="(disableActions$ | async)">
                                        </mat-slide-toggle>
                                    </td>
                                    <td *ngIf="!!plan.controls.includePlan">
                                        <mat-slide-toggle [matTooltip]="plan.controls.includePlan.disabled ? 
                                                (plan.value.id | getmutexplan : plan.value.formId : 
                                                    (pPComponentService.getSelectedProduct$ | async).code :
                                                    (pPComponentService.getSelectedProduct$ | async).id) : ''"
                                            matTooltipClass="tooltip-line-break text-align-left p-2"
                                            formControlName="includePlan" [disabled]="(disableActions$ | async)">
                                        </mat-slide-toggle>
                                    </td>
                                    <td *ngIf="((pPComponentService.getSelectedProduct$ | async).code | checkmutexproduct)
                                        && !plan.controls.includePlan"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab
                    *ngIf="((pPComponentService.getSelectedProductConfig$ | async) | getpencildefaults : productDetailsForm).length"
                    formGroupName="global" label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_PENCIL' | translate}}">
                    <div class="p-2 mt-2 plans-container">
                        <table aria-describedby="tableHd" class="table example-full-width mt-3">
                            <thead>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_SCLASS' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_OFFER' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_ENABLED' |
                                    translate}}
                                </th>
                            </thead>
                            <tbody formArrayName="firstPencilDefaults">
                                <tr [ngClass]="{'border-bottom': !lastRow}"
                                    *ngFor="let pencildefault of ((pPComponentService.getSelectedProductConfig$ | async)
                                | getpencildefaults : productDetailsForm), let i = index, last as lastRow, first as firstRow" [formGroupName]="i">
                                    <td class="text-cap">
                                        {{ firstRow ? pencildefault.value.salesClass :
                                        (productDetailsForm.controls.global.controls.firstPencilDefaults.controls[i].value.salesClass
                                        ==
                                        productDetailsForm.controls.global.controls.firstPencilDefaults.controls[i-1].value.salesClass
                                        ? '': pencildefault.value.salesClass) }}
                                    </td>
                                    <td>
                                        <span>{{pencildefault.value.offerType}}</span>
                                    </td>
                                    <td class="pt-3">
                                        <mat-slide-toggle [disabled]="(disableActions$ | async)"
                                            [matTooltip]="pencildefault.controls.enabled.disabled ? (productDetailsForm | getpencilenableproducts : pencildefault.value.salesClass : pencildefault.value.offerType) : ''"
                                            matTooltipClass="tooltip-line-break text-align-left p-2"
                                            formControlName="enabled">
                                        </mat-slide-toggle>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="(pPComponentService.getSelectedProduct$ | async)?.providerId === jmnaProviderId"
                    label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_OFFER_ENABLED' | translate}}">
                    <div class="p-2 mt-2 plans-container">
                        <table aria-describedby="tableHd" class="table example-full-width mt-3">
                            <thead>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_OFFER' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_ENABLED' |
                                    translate}}
                                </th>
                            </thead>
                            <tbody formGroupName="offers">
                                <tr [ngClass]="{'border-bottom': !lastRow}"
                                    *ngFor="let offerType of (pPComponentService.getSelectedProduct$ | async).code | offertypesfilter, last as lastRow"
                                    [formGroupName]="offerType">
                                    <td>
                                        <span class="text-cap">{{offerType}}</span>
                                    </td>
                                    <td class="d-flex">
                                        <mat-slide-toggle formControlName="enabled"
                                            [disabled]="(disableActions$ | async)">
                                        </mat-slide-toggle>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-tab>
                <!---Code Template For NGPE-6849 VPP Gross Profit Pricing Begins-->
                <mat-tab
                    [disabled]="!(pPComponentService.isTfsDealer && (pPComponentService.getSelectedProduct$ | async)?.providerId != null)"
                    formGroupName="global" label="{{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_GP_BY_SERIES' | translate}}"
                    class="pos-relative" *ngIf="(pPComponentService.getSelectedProduct$ | async)?.isRegulated !== 'Y'">
                    <ng-container formGroupName="gp">
                        <ng-container formGroupName="adjustments">
                            <ng-container *ngFor="let salesClass of (salesClasses$ | async)">
                                <div *ngIf="((pPComponentService.getSelectedProduct$ | async).providerId == nontmisProviderId) || ((pPComponentService.getSelectedProduct$ | async).id | checksalesclassdataexistspipe : salesClass)"
                                    class="col-12 mt-2 p-2 vehicle-type-div tfs-pp-color" [formGroupName]="salesClass">
                                    <span
                                        class="vehicle-type-section-title text-cap">{{'LOCALIZATION.PROTECTION_PRODUCTS.DEFAULT_HEADER_NOTE'
                                        | translate:({"salesClass": salesClass})}}</span>
                                    <mat-tab-group class="mt-3" mat-stretch-tabs animationDuration="0ms"
                                        (selectedIndexChange)="selectedOfferTypeTabIndex = $event"
                                        [selectedIndex]="selectedOfferTypeTabIndex">
                                        <ng-container
                                            *ngFor="let offerType of (offerTypes$ | async),let offerIndex = index">
                                            <mat-tab [label]="offerType"
                                                [disabled]="!(((pPComponentService.getSelectedProductConfig$ | async).providerId == nontmisProviderId) || ((pPComponentService.getSelectedProduct$ | async).id | checkOffertypeDataExists : offerType : salesClass))">
                                                <table aria-describedby="tableHd" class="table example-full-width mt-3"
                                                    *ngIf="((pPComponentService.getSelectedProductConfig$ | async).providerId == nontmisProviderId) || ((pPComponentService.getSelectedProduct$ | async).id | checkOffertypeDataExists : offerType : salesClass) else noDefaultsMessage">
                                                    <thead>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MAKE' |
                                                            translate}}</th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_SERIES' |
                                                            translate}}
                                                        </th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_ADJUSTMENT' |
                                                            translate}}
                                                        </th>
                                                        <th scope="col" class="pl-2">
                                                            {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MEASURE' |
                                                            translate}}
                                                        </th>
                                                        <th>
                                                            <input type="button" *ngIf="!(disableActions$ | async)"
                                                                class="primary-button small-button ml-3"
                                                                (click)="addFieldValue(offerType,salesClass)"
                                                                value="{{'LOCALIZATION.COMMON.ADD' | translate}}" />
                                                        </th>
                                                        <th
                                                            *ngIf="((pPComponentService.getSelectedProductConfig$ | async)
                                                        | getGpAdjustments : productDetailsForm : salesClass : offerType ).length > 0">
                                                            <input type="button" *ngIf="!(disableActions$ | async)"
                                                                class="primary-button small-button ml-3 copy-to-button"
                                                                (click)="copyTo($event, offerType,salesClass)"
                                                                value="{{'LOCALIZATION.COMMON.COPY_TO' | translate}}" />
                                                        </th>
                                                    </thead>
                                                    <tbody [formArrayName]="offerType"
                                                        *ngIf="showDetails && ((pPComponentService.getSelectedProductConfig$ | async)
                                                | getGpAdjustments : productDetailsForm : salesClass : offerType ).length > 0 else noDataMessage">
                                                        <tr *ngFor="let term of ((pPComponentService.getSelectedProductConfig$ | async)
                                                    | getGpAdjustments : productDetailsForm : salesClass : offerType ), let i = index, last as lastRow"
                                                            [formGroupName]="i">
                                                            <td class="va-middle">
                                                                <div class="pos-relative">
                                                                    <mat-select class="width-105px"
                                                                        [disabled]="(disableActions$ | async)"
                                                                        formControlName="make"
                                                                        (selectionChange)="onMakeChange($event.value,salesClass,offerType,term)"
                                                                        [ngClass]="validateFormGroup(term)">
                                                                        <ng-container
                                                                            *ngFor="let make of (brandTmisList$ | async)">
                                                                            <mat-option
                                                                                *ngIf="(salesClass === 'used' || make.id !== 'allntl')"
                                                                                [value]="make.id">
                                                                                {{make.name}}
                                                                            </mat-option>
                                                                        </ng-container>
                                                                    </mat-select>
                                                                </div>
                                                            </td>
                                                            <td class="va-middle">
                                                                <div class="pos-relative">
                                                                    <mat-select selectAll formControlName="series"
                                                                        [disabled]="(disableActions$ | async)"
                                                                        class="width-105px"
                                                                        [matTooltip]="term.controls.series.value | getseriesname : (vehicleConfig$ | async) : term.controls.make.value"
                                                                        [ngClass]="validateFormGroup(term)" multiple>
                                                                        <mat-option [value]="allOptionValue">
                                                                            {{'LOCALIZATION.COMMON.ALL'
                                                                            | translate}}</mat-option>
                                                                        <mat-option
                                                                            *ngFor="let series of (term.controls.make.value) | getbrandseries : (vehicleConfig$ | async)"
                                                                            [value]="series.code"
                                                                            [disabled]="series | disableonallselection">
                                                                            {{series.name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </td>
                                                            <td class="va-middle">
                                                                <div *ngIf="checkgptype(salesClass,offerType) as gpType"
                                                                    class="pos-relative">
                                                                    <span class="dollar-symbol"
                                                                        *ngIf="gpType == 'amount'">$&nbsp;</span>
                                                                    <input type="number"
                                                                        [attr.disabled]="(disableActions$ | async) ? true : null"
                                                                        *ngIf="(pPComponentService.getSelectedProductConfig$ | async) as data"
                                                                        formControlName="adjustment"
                                                                        [ngClass]="validateFormGroup(term)"
                                                                        [class.dollar-input]="gpType == 'amount'"
                                                                        [class.percent-input]="gpType == 'percent'"
                                                                        class="product-input-price no-number-arrow" />
                                                                    <span class="percent-symbol"
                                                                        *ngIf="gpType == 'percent'">%&nbsp;</span>
                                                                </div>
                                                            </td>
                                                            <td class="va-middle">
                                                                <div *ngIf="checkgptype(salesClass,offerType) as gpType"
                                                                    class="pos-relative">
                                                                    <span class="dollar-symbol"
                                                                        *ngIf="gpType == 'amount'">$&nbsp;</span>
                                                                    <input type="number" #Adjusted_GP_Input numbersOnly
                                                                        [attr.disabled]="(disableActions$ | async) ? true : null"
                                                                        *ngIf="(pPComponentService.getSelectedProductConfig$ | async) as data"
                                                                        (keyup)="change_adjusted_gp($event,data.global.gp.value,salesClass,offerType,term,data.global.maxPrice)"
                                                                        [class.dollar-input]="gpType == 'amount'"
                                                                        [class.percent-input]="gpType == 'percent'"
                                                                        class="product-input-price no-number-arrow"
                                                                        [matTooltip]="(Adjusted_GP_Input.value < 0 ? ('LOCALIZATION.PROTECTION_PRODUCTS.ADJUSTED_GP_NEGATIVE_VALIDATION' | translate)
                                                                        : gpType == 'amount' && data.global.maxPrice && (Adjusted_GP_Input.value > data.global.maxPrice) ? 
                                                                        ('LOCALIZATION.PROTECTION_PRODUCTS.ADJUSTED_GP_TOOLTIP' | translate : {price:data.global.maxPrice}) : '')"
                                                                        [matTooltipDisabled]="(Adjusted_GP_Input.value < data.global.maxPrice) && Adjusted_GP_Input.value >= 0"
                                                                        [ngClass]="{'inputBoxRed': (Adjusted_GP_Input.value < 0) || (gpType == 'amount' && data.global.maxPrice && Adjusted_GP_Input.value > data.global.maxPrice)}"
                                                                        value="{{(data?.global?.gp?.value + term.controls.adjustment.value)}}" />
                                                                    <span class="percent-symbol"
                                                                        *ngIf="gpType == 'percent'">%&nbsp;</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <mat-icon style="position: relative;top: 7px;"
                                                                    *ngIf="!(disableActions$ | async)" class="pointer"
                                                                    class="highlight_off"
                                                                    (click)="deleteFieldValue(offerType,salesClass,term)">
                                                                    close</mat-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </mat-tab>
                                        </ng-container>
                                        <ng-template #noDefaultsMessage>
                                            <div class="d-flex">
                                                <span
                                                    class="d-flex m-auto pt-2 font-semibold">{{'LOCALIZATION.PROTECTION_PRODUCTS.OFFER_VALID_TEXT'
                                                    | translate}}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template #noDataMessage>
                                            <div class="d-flex">
                                                <span
                                                    class="d-flex m-auto pt-2 font-semibold">{{'LOCALIZATION.PROTECTION_PRODUCTS.NO_ADJUSTMENTS_DATA'
                                                    | translate}}</span>
                                            </div>
                                        </ng-template>
                                    </mat-tab-group>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-tab>
                <!---Code Template For NGPE-6849 VPP Gross Profit Pricing Ends-->
                <mat-tab formGroupName="global" *ngIf="pPComponentService.hasPacAccess
                    && (pPComponentService.getSelectedProductConfig$ | async)?.global?.pacManagement 
                    && !(pPComponentService.getSelectedProduct$ | async)?.usePAC
                    && (pPComponentService.getSelectedProduct$ | async)?.isRegulated !== 'Y'">
                    <ng-template mat-tab-label>
                        <em class="error-alert tmi tmi-alert"
                            *ngIf="productDetailsForm.controls.global.controls.pacManagement.invalid"></em>
                        {{'LOCALIZATION.PROTECTION_PRODUCTS.TAB_PAC' | translate}}
                    </ng-template>
                    <div formGroupName="pacManagement" class="d-flex" *ngIf="showDetails">
                        <div class="d-flex flex-column mr-4">
                            <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_AMT' |
                                translate}}</mat-label>
                            <div class="pos-relative">
                                <span class="dollar-symbol">$&nbsp;</span>
                                <input class="product-input dollar-input no-number-arrow" numbersOnly
                                    [ngClass]="{'input-error': productDetailsForm.controls.global.controls.pacManagement.controls.amount.invalid}"
                                    [matTooltip]="productDetailsForm.controls.global.controls.pacManagement.controls.amount.invalid
                                    ? ('LOCALIZATION.PROTECTION_PRODUCTS.PP_PAC_LIMIT_TOOLTIP' | translate 
                                    : {min: pPComponentService.ppPacLimits?.min, max: pPComponentService.ppPacLimits?.max}) : ''"
                                    type="number" formControlName="amount"
                                    [attr.disabled]="(disableActions$ | async) ? true : null">
                            </div>
                        </div>
                        <div class="d-flex flex-column mr-4 pos-relative">
                            <mat-label class="my-2 fw-bold">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_EFF_DATE' |
                                translate}}</mat-label>
                            <input matInput autocomplete="off" formControlName="effectiveDate"
                                [disabled]="(disableActions$ | async)"
                                [ngClass]="{'input-error': productDetailsForm.controls.global.controls.pacManagement.errors?.invalidPacEffectiveDate || productDetailsForm.controls.global.controls.pacManagement.controls.effectiveDate.invalid}"
                                [matTooltip]="productDetailsForm.controls.global.controls.pacManagement.errors?.invalidPacEffectiveDate || productDetailsForm.controls.global.controls.pacManagement.controls.effectiveDate.invalid ? 'Please select a current or future date.' : ''"
                                class="product-input date-picker-input" [matDatepickerFilter]="currentDateFilter"
                                [matDatepicker]="pacEffectiveDate">
                            <mat-datepicker-toggle disableRipple matSuffix [for]="pacEffectiveDate"
                                [disabled]="(disableActions$ | async)"
                                style="position: absolute; top: 25px; left: 110px;">
                                <mat-icon matDatepickerToggleIcon>
                                    <em class="tmi tmi-date"></em>
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pacEffectiveDate></mat-datepicker>
                        </div>
                    </div>
                    <div *ngIf="showDetails && displayPacChangelogs">
                        <table aria-describedby="tableHd" class="table example-full-width mt-3">
                            <thead>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_USER_ID' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_COST' |
                                    translate}}</th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_CREATED' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_EFFECTIVE' |
                                    translate}}
                                </th>
                                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MODIFIED' |
                                    translate}}
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pacLog of (pacChangelogs$ | async)" class="border-bottom">
                                    <td class="va-middle">
                                        <mat-label>{{pacLog.userId}}</mat-label>
                                    </td>
                                    <td class="va-middle">
                                        <mat-label>{{pacLog.pacCost | currency : 'USD'}}</mat-label>
                                    </td>
                                    <td class="va-middle">
                                        <mat-label>{{pacLog.pacCreatedDate | date : 'MM-dd-yyyy'}}
                                        </mat-label>
                                    </td>
                                    <td class="va-middle">
                                        <mat-label>{{pacLog.pacEffectiveDate | date : 'MM-dd-yyyy'}}
                                        </mat-label>
                                    </td>
                                    <td class="va-middle">
                                        <mat-label>{{pacLog.pacModifiedDate | date : 'MM-dd-yyyy'}}
                                        </mat-label>
                                    </td>
                                </tr>
                                <tr *ngIf="!((pacChangelogs$ | async)?.length > 0)">
                                    <mat-label class="d-flex pt-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.NO_LOGS' |
                                        translate}}</mat-label>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pac-loader-container mt-2" [ngClass]="{'d-none': showDetails}">
                        <dd-spinner></dd-spinner>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div *ngIf="!(pPComponentService.getSelectedProduct$ | async)" class="d-flex justify-content-center">
        <mat-label class="d-flex pt-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.PROD_VALID_TEXT' | translate}}</mat-label>
    </div>
</div>