import { PlanDescriptionPipe } from './pipes/plan-description.pipe';
import { ProductsFilterPipe } from './pipes/products-filter.pipe';
import { ProductDescriptionPipe } from './pipes/product-description.pipe';
import { PpOptionsFilterPipe } from './pipes/pp-options-filter.pipe';
import { GlobalModule } from './../../global/global.module';
import { SharedModule } from './../../shared/shared.module';
import { ProtectionProductRoutingModule } from './protection-product-routing-module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProtectionProductComponent } from './protection-product.component';
import { ProtectionProductsListComponent } from './protection-products-list/protection-products-list.component';
import { ProtectionProductsTableComponent } from './protection-product-table/protection-products-table.component';
import { ProtectionProductDetailsComponent } from './protection-product-details/protection-product-details.component';
import { PlansFilterPipe } from './pipes/plans-filter.pipe';
import { OfferTypesFilterPipe } from './pipes/offer-types-filter.pipe';
import { CheckSalesClassDataExistsPipe } from './pipes/check-salesclass-data-exists.pipe';
import { GetFormArrayPipe } from './pipes/get-form-array.pipe';
import { PennProductsFilterPipe } from './pipes/penn-products-filter.pipe';
import { GetOrUpdateProductFormPipe } from './pipes/get-or-update-product-form.pipe';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS } from '../../shared/services/luxon-date-adapter';
import { GetPlansArrayPipe } from './pipes/get-plans-array.pipe';
import { CheckProductDefaultsLoadedPipe } from './pipes/check-product-defaults-loaded.pipe';
import { GetPencilDefaultsPipe } from './pipes/get-pencil-defaults.pipe';
import { PennRateBookCodeFilterPipe } from './pipes/penn-rate-book-code-filter.pipe';
import { GetRateBookDescriptionPipe } from './pipes/get-rate-book-description.pipe';
import { GetPencilEnabledProductsPipe } from './pipes/get-pencil-enabled-products.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CheckMutexProductPipe } from './pipes/check-mutex-product.pipe';
import { GetMutexPlanPipe } from './pipes/get-mutex-plan.pipe';
import { CheckGpAdjustmentsPipe } from './pipes/check-gp-adjustments.pipe';
import { CheckOffertypeDataExistsPipe } from './pipes/check-offertype-data-exists.pipe';
import { GetBrandSeriesPipe } from './pipes/get-brand-series-pp.pipe';
import { GetGpAdjustmentsPipe } from './pipes/get-gp-adjustments.pipe';
import { DisableOnAllSelectionPipe } from './pipes/disable-on-all-selection.pipe';
import { HasExcludedPlansPipe } from './pipes/has-excluded-plans.pipe';
import { ProtectionProductCopyOfferComponent } from './protection-product-copy-offer/protection-product-copy-offer.component';
import { GetSeriesNamePipe } from './pipes/get-series-name-pp.pipe';
@NgModule({
  declarations: [
    ProtectionProductComponent,
    ProtectionProductsListComponent,
    ProtectionProductsTableComponent,
    ProtectionProductDetailsComponent,
    ProtectionProductCopyOfferComponent,
    PlansFilterPipe,
    PpOptionsFilterPipe,
    ProductDescriptionPipe,
    ProductsFilterPipe,
    PlanDescriptionPipe,
    OfferTypesFilterPipe,
    CheckSalesClassDataExistsPipe,
    GetFormArrayPipe,
    PennProductsFilterPipe,
    PennRateBookCodeFilterPipe,
    GetRateBookDescriptionPipe,
    GetOrUpdateProductFormPipe,
    GetPlansArrayPipe,
    CheckProductDefaultsLoadedPipe,
    GetPencilDefaultsPipe,
    GetPencilEnabledProductsPipe,
    CheckMutexProductPipe,
    GetMutexPlanPipe,
    CheckGpAdjustmentsPipe,
    CheckOffertypeDataExistsPipe,
    GetBrandSeriesPipe,
    GetGpAdjustmentsPipe,
    DisableOnAllSelectionPipe,
    HasExcludedPlansPipe,
    GetSeriesNamePipe
  ],
  imports: [
    ProtectionProductRoutingModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule, GlobalModule, SharedModule
  ],
  providers: [
    LuxonDateAdapter,
    ProductDescriptionPipe,
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
  ]
})
export class ProtectionProductModule { }
