import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { LuxonDateAdapter } from '../../shared/services/luxon-date-adapter';
import { UserProfileService } from '@toyota/dd365-platform-library';
import * as dealerConfigurationActions from '../../store/dealer-configuration/dealer-configuration.actions';
import { DealerConfigurationState } from '../../store/dealer-configuration/dealer-configuration.reducer';

import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import _ from 'lodash';
import { Store } from '@ngrx/store';
import { selectDealerConfiguration, selectDisableActions, selectIsLoading, selectSystemConfigLoaded, selectvehiclePac, selectVehiclePacLimits } from '../../store/dealer-configuration/dealer-configuration.selectors';
import { combineLatest, Observable } from 'rxjs';
import { VehiclePacComponentService } from './vehicle-pac.component.service';
import { TranslateService } from '@ngx-translate/core';
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
import { selectVehicleConfig } from '../../store/vehicle-config/vehicle-config.selectors';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { GetBrandSeriesPipe } from './pipes/get-brand-series.pipe';
import { GenericSelections } from '../../shared/enums/generic-selections.enum';
import { IDealerConfiguration } from '../../shared/models/dealer-configuration.model';
import { filter, take } from 'rxjs/operators';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { IVehiclePacLimits } from '../../shared/models/system-configuration.model';


@Component({
  selector: 'app-vehicle-pac',
  templateUrl: './vehicle-pac.component.html',
  styleUrls: ['./vehicle-pac.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter }
  ]
})
export class VehiclePacComponent implements OnInit, OnDestroy {

  private subs: SubscriptionList = {};
  dealerCode: string;
  selectDealerConfigLoading$: Observable<boolean>;
  selectSystemConfigLoaded$: Observable<boolean>;
  disableActions$: Observable<boolean>;
  vehicleConfig$: Observable<IVehicleBrand[]>;
  allOptionValue = GenericSelections.ALL;
  vehiclePacLimits: IVehiclePacLimits;
  selectDealerConfiguration$: any;
  dealerConfig: IDealerConfiguration;
  userRoles: string[];
  readOnlyFlag = false;

  constructor(
    private snackBar: MatSnackBar,
    private readonly userProfileService: UserProfileService,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly vehiclePacComponentService: VehiclePacComponentService,
    readonly translateService: TranslateService,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    private getBrandSeriesPipe: GetBrandSeriesPipe,
    private _userProfileService: UserProfileService,
    private readonly dealerInfoService: DealerInfoService
  ) { }

  ngOnInit(): void {
    this.dealerCode = this.userProfileService.getProfile().dealerCd;

    this.userRoles = this._userProfileService.getProfile().roles;
    this.readOnlyFlag = this.userRoles.indexOf('DD_PAC_Management') < 0;
    if (this.readOnlyFlag) {
      this.vehiclePacComponentService.isDisableFormActions = true;
    }

    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadDealerConfiguration({ dealerCode: this.dealerCode }));
    this.dealerConfigurationState.dispatch(new dealerConfigurationActions.LoadSystemConfiguration({ distributor: this.dealerInfoService.getDealerBankDistributor() }));
    this.selectDealerConfigLoading$ = this.dealerConfigurationState.select(selectIsLoading);
    this.selectSystemConfigLoaded$ = this.dealerConfigurationState.select(selectSystemConfigLoaded);
    this.vehicleConfig$ = this.vehicleConfigState.select(selectVehicleConfig);


    this.subs.selectDisableActions = this.dealerConfigurationState.select(selectDisableActions).subscribe((action) => {
      if (!this.readOnlyFlag) {
        this.vehiclePacComponentService.isDisableFormActions = action;
      }
      this.getAndUpdateVehiclePacValue();
    });
  }

  getAndUpdateVehiclePacValue() {
    this.subs.selectVehiclePacData = combineLatest([
      this.dealerConfigurationState.select(selectvehiclePac),
      this.dealerConfigurationState.select(selectVehiclePacLimits)
    ]).pipe(
      filter(([vehiclePac, vehiclePacLimits]) =>
        !!vehiclePac && !!vehiclePacLimits)
    ).subscribe(([vehiclePac, vehiclePacLimits]) => {
      this.vehiclePacLimits = vehiclePacLimits;
      this.vehiclePacComponentService.vehiclePac = vehiclePac;
      this.vehiclePacComponentService.buildVehiclePacForm(vehiclePacLimits);
    });
  }

  saveVehiclePac() {
    if (this.vehiclePacComponentService.vehiclePacForm.valid) {
      this.dealerConfigurationState
        .select(selectDealerConfiguration)
        .pipe(take(1))
        .subscribe((dealerConfig) => {
          const dealerConfiguration = _.cloneDeep(dealerConfig);
          const vehiclePacFormData = _.cloneDeep(this.vehiclePacComponentService.vehiclePacForm.value);
          _.map(vehiclePacFormData, salesClass => {
            _.map(salesClass.adjustments, adj => {
              const make = adj.make;
              const makeName = this.vehiclePacComponentService.getBrandById(make).name;

              const modelNames: string[] = [];
              const series = this.getBrandSeriesPipe.transform(make, this.vehiclePacComponentService.vehicleConfig);
              const model = adj.series;
              model.forEach(seriesId => {
                modelNames.push(seriesId === this.allOptionValue
                  ? _.startCase(this.allOptionValue)
                  : this.vehiclePacComponentService.getModelByCode(series, seriesId).name);
              });

              adj.makeName = makeName;
              adj.seriesNames = modelNames;
              delete adj.adjustedPac;
            })
          })

          dealerConfiguration.vehiclePac = vehiclePacFormData;
          this.dealerConfigurationState.dispatch(
            new dealerConfigurationActions.UpdateDealerConfiguration({
              dealerCode: this.dealerCode,
              dealerConfiguration,
            })
          );
        });

    } else {
      this.snackBar.open(
        this.translateService.instant(
          'LOCALIZATION.VEHICLE_PAC.INVALID_CONFIG'
        ),
        this.translateService.instant('LOCALIZATION.COMMON.DISMISS'),
        { duration: 4000 }
      );
    }
  }

  resetVehiclePac() {
    this.vehiclePacComponentService.resetVehiclePac(this.vehiclePacLimits);
  }

  addPacSeries(salesClass: string) {
    const pacCostControl = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('pacCost') as FormControl;
    const adjArray = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
    this.vehiclePacComponentService.buildAdjustmentForm(adjArray, pacCostControl, this.vehiclePacLimits);
  }

  deletePacSeries(salesClass: string, index: any) {
    const adjArray = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
    adjArray.removeAt(index);
  }

  getTooltip(control: AbstractControl): string {
    if (control.hasError('duplicateAdj')) {
      return this.translateService.instant('LOCALIZATION.VEHICLE_PAC.ADJ_OVERLAPPING_ERROR');
    } else if (control.hasError('notMin')) {
      return this.translateService.instant('LOCALIZATION.VEHICLE_PAC.ADJUSTED_PAC_MIN', { min: this.vehiclePacLimits.min });
    } else if (control.hasError('notMax')) {
      return this.translateService.instant('LOCALIZATION.VEHICLE_PAC.ADJUSTED_PAC_MAX', { max: this.vehiclePacLimits.max });
    }
    return '';
  }

  getAdjControls(salesClass: string): FormArray {
    return this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
  }

  getAdjustmentLength(salesClass: string) {
    const adjustment = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
    return adjustment.length;
  }

  changeMake(salesClass: string, adjustment: FormGroup, index: any) {
    adjustment.get('series').setValue([]);
    const adjArray = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
    this.vehiclePacComponentService.triggerSerAdjControlsValidation(adjArray);
  }

  changeAdjustment(adj: any, salesClass: string) {
    const adjustment = adj.get('adjustment').value;
    const pacCost = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('pacCost').value;
    const adjArray = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;

    adj.get('adjustedPac').setValue(pacCost + adjustment);
    this.vehiclePacComponentService.triggerSerAdjControlsValidation(adjArray);
  }

  changeAdjustedPac(adj: any, salesClass: string) {
    const pacCost = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('pacCost').value;
    if (adj.get('adjustedPac').value == null) {
      const adjustment = adj.get('adjustment').value;
      adj.get('adjustedPac').setValue(pacCost + adjustment);
    } else {
      const adjustedPac = adj.get('adjustedPac').value;
      adj.get('adjustment').setValue(pacCost - adjustedPac);
    }
    const adjArray = this.vehiclePacComponentService.vehiclePacForm.get(salesClass).get('adjustments') as FormArray;
    this.vehiclePacComponentService.triggerSerAdjControlsValidation(adjArray);
  }

  isInvalid(adj: any, controlName: string) {
    const control = adj.get(controlName);
    return control.invalid ? 'inputBoxRed' : 'inputBoxBlack';
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
    this.vehiclePacComponentService.destroySubs();
  }
}
