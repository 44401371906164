import { Injectable } from '@angular/core';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IVehiclePacAdjustment, IVehiclePacConfig } from '../../shared/models/dealer-configuration.model';
import { Store } from '@ngrx/store';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { selectVehicleBrands, selectVehicleConfig } from '../../store/vehicle-config/vehicle-config.selectors';
import { take } from 'rxjs/operators';
import { Brands } from '../../shared/enums/brands.enum';
import * as vehicleConfigActions from '../../store/vehicle-config/vehicle-config.actions';
import _ from 'lodash';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import { IBrand } from '../../shared/models/brand.model';
import { DealerConfigurationState } from '../../store/dealer-configuration/dealer-configuration.reducer';
import { selectBrands } from '../../store/dealer-configuration/dealer-configuration.selectors';
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
import { IVehiclePacLimits } from '../../shared/models/system-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class VehiclePacComponentService {
    public initialFormValues;
    public vehiclePac: IVehiclePacConfig;
    public vehiclePacForm: FormGroup;
    configurableSalesClasses: string[] = [
        SalesClasses.NEW,
        SalesClasses.CERTIFIED,
        SalesClasses.USED,
    ];
    rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
    subCount: number = 0;
    private subs: SubscriptionList = {};
    vehicleBrands: IBrand[] = [];
    vehicleConfig: IVehicleBrand[] = [];
    isDisableFormActions = false;

    constructor(
        private fb: FormBuilder,
        private readonly dealerConfigurationState: Store<DealerConfigurationState>,
        private readonly vehicleConfigState: Store<VehicleConfigState>,
    ) {
        this.subs.brandListData = this.dealerConfigurationState.select(selectBrands).subscribe(data => {
            this.vehicleBrands = data;
        });
        this.subs.selectVehicleConfigSub = this.vehicleConfigState.select(selectVehicleConfig).subscribe(vehicleConfig => {
            this.vehicleConfig = vehicleConfig;
        });
    }

    public buildVehiclePacForm(vehiclePacLimits: IVehiclePacLimits) {
        const salesClassGroup: { [key: string]: FormGroup } = {};

        this.configurableSalesClasses.forEach(salesClass => {
            let pacCostValue = 0;
            if (this.vehiclePac && this.vehiclePac[salesClass]?.pacCost) {
                pacCostValue = this.vehiclePac[salesClass].pacCost;
            }
            const pacCostControl = this.fb.control({ value: pacCostValue, disabled: this.isDisableFormActions }, [Validators.required, this.maxPacValidator(vehiclePacLimits)]);
            salesClassGroup[salesClass] = this.fb.group({
                pacCost: pacCostControl,
                adjustments: this.fb.array([])
            });

            if (this.vehiclePac && this.vehiclePac[salesClass]?.adjustments && this.vehiclePac[salesClass]?.adjustments.length > 0) {
                const adjArray = salesClassGroup[salesClass].controls.adjustments as FormArray;
                this.vehiclePac[salesClass]?.adjustments.forEach((adjustment) => {
                    this.buildAdjustmentForm(adjArray, pacCostControl, vehiclePacLimits, adjustment);
                });
            }
        });

        this.vehiclePacForm = this.fb.group(salesClassGroup);
        this.initialFormValues = this.vehiclePacForm.value;
    }

    buildAdjustmentForm(adjArray, pacCostControl, vehiclePacLimits: IVehiclePacLimits, adj?) {
        const adjGroup = this.fb.group({
            make: new FormControl({ value: adj && adj.make ? adj.make : [], disabled: this.isDisableFormActions }, [Validators.required]),
            series: new FormControl({ value: adj && adj.series ? adj.series : [], disabled: this.isDisableFormActions }, [Validators.required]),
            adjustment: new FormControl({ value: adj && adj.adjustment ? adj.adjustment : 0, disabled: this.isDisableFormActions }, [
                Validators.required,
            ]),
            adjustedPac: new FormControl({ value: adj && adj.adjustment ? (adj.adjustment + pacCostControl.value) : pacCostControl.value, disabled: this.isDisableFormActions })
        })
        adjGroup.setValidators(this.validateAdjustment(vehiclePacLimits));
        adjArray.push(adjGroup);

        this.subs['makeSub' + this.subCount++] = adjGroup.get('make').valueChanges.subscribe(() => {
            this.triggerSerAdjControlsValidation(adjArray);
        });
        this.subs['seriesSub' + this.subCount++] = adjGroup.get('series').valueChanges.subscribe(() => {
            this.triggerSerAdjControlsValidation(adjArray);
        });
        /*this.subs['adjustmentSub' + this.subCount++] = adjGroup.get('adjustment').valueChanges.subscribe((adjustmentValue) => {
            adjGroup.get('adjustedPac').setValue(adjustmentValue + pacCostControl.value);
            this.triggerSerAdjControlsValidation(adjArray);
        });*/
        this.subs['pacCostSub' + this.subCount++] = pacCostControl.valueChanges.subscribe((pacValue) => {
            const adjustmentValue = adjGroup.get('adjustment').value;
            adjGroup.get('adjustedPac').setValue(adjustmentValue + pacCostControl.value);
            pacCostControl.updateValueAndValidity({ emitEvent: false });
        });
    }

    maxPacValidator(vehiclePacLimits: IVehiclePacLimits): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value > vehiclePacLimits.max) {
                return { notMax: true, value: vehiclePacLimits.max };
            } else if (value < vehiclePacLimits.min) {
                return { notMin: true, value: vehiclePacLimits.min };
            }
            return null;
        };
    }

    validateAdjustment(vehiclePacLimits: IVehiclePacLimits): ValidatorFn {
        return (group: FormGroup): ValidationErrors => {
            const adjGroup = group as FormGroup;
            const adjArray = adjGroup.parent as FormArray
            const make = adjGroup.get('make').value;
            const series = adjGroup.get('series').value || [];
            const adjustedPac = adjGroup.get('adjustedPac').value;
            const otherAdj = adjArray.controls.filter((x) => !_.isEqual(x, adjGroup));
            const duplicateAdj = otherAdj.some(
                (x) => {
                    const xMake = x.get('make').value;
                    const xSeries = x.get('series').value || [];
                    // const xAdjustment = x.get('adjustment').value;
                    const makeMatch = make === xMake;
                    const seriesMatch = xSeries.some(item => series.includes(item) || series.includes('all') || item === 'all');
                    // const adjustmentMatch = adjustment === xAdjustment;

                    return makeMatch && seriesMatch
                }
            );
            if (duplicateAdj) {
                group.get('make').setErrors({ duplicateAdj: true });
                group.get('series').setErrors({ duplicateAdj: true });
                group.get('adjustment').setErrors({ duplicateAdj: true });
                group.get('adjustedPac').setErrors({ duplicateAdj: true });
            } else if (adjustedPac < vehiclePacLimits.min) {
                group.get('adjustedPac').setErrors({ notMin: true });
            } else if (adjustedPac > vehiclePacLimits.max) {
                group.get('adjustedPac').setErrors({ notMax: true });
            }
            return;
        };
    }

    triggerSerAdjControlsValidation(adjArray) {
        setTimeout(() => {
            adjArray.controls.forEach(adjGroup => {
                adjGroup.get('make').updateValueAndValidity({ emitEvent: false });
                adjGroup.get('series').updateValueAndValidity({ emitEvent: false });
                adjGroup.get('adjustment').updateValueAndValidity({ emitEvent: false });
                adjGroup.get('adjustedPac').updateValueAndValidity({ emitEvent: false });
            });
        }, 0);
    }

    resetVehiclePac(vehiclePacLimits: IVehiclePacLimits) {
        this.vehiclePacForm.reset(this.initialFormValues, { emitEvent: false });

        this.configurableSalesClasses.forEach(salesClass => {
            const salesClassGroup = this.vehiclePacForm.get(salesClass) as FormGroup;
            salesClassGroup.removeControl('adjustments');
            salesClassGroup.addControl('adjustments', this.fb.array([]));
            const adjArray = salesClassGroup.get('adjustments') as FormArray;
            const pacCostControl = salesClassGroup.get('pacCost') as FormControl;
            this.initialFormValues[salesClass]?.adjustments.forEach((adjustment) => {
                this.buildAdjustmentForm(adjArray, pacCostControl, vehiclePacLimits, adjustment);
            });
        });

        this.vehiclePacForm.updateValueAndValidity({ emitEvent: false });
    }

    checkAndfetchVehicleConfig(brand: string, vehicleConfigState: Store<VehicleConfigState>) {
        vehicleConfigState.select(selectVehicleBrands).pipe(take(1)).subscribe(brands => {
            if (_.indexOf(brands, brand) < 0) {
                if (this.rtiBrands.includes(brand)) {
                    vehicleConfigState.dispatch(new vehicleConfigActions.LoadRTIVehicleConfig({ brand }));
                }
            }
        });
    }

    getBrandById(id) {
        return _.find(this.vehicleBrands, ['id', id]) || id;
    }

    getModelByCode(data, code) {
        return _.find(data, ['code', code]) || code;
    }

    /* istanbul ignore next */
    destroySubs() {
        unsubscribeSubscriptions(this.subs);
    }
}