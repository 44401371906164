import { IFieldConfig, ITaxCategory, ITermDuration } from './../../shared/models/protection-products.model';
import { IPennProvider, IProduct } from '../../shared/models/product-offerings.model';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProtectionProductsState } from './protection-products.reducer';
import { FeatureKeys } from '../app.reducer';

export const selectProtectionProductsFeature = createFeatureSelector<ProtectionProductsState>(FeatureKeys.PROTECTIONPRODUCTS);

export const selectMaxVisiblePP = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.protectionProducts.maxVisiblePP
);

export const selectProtectionProducts = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.protectionProducts
);

export const selectProductOfferings = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.productOfferings
);

export const selectPennProductDefaultsList = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pennProductDefaultsList
);

export const selectLastPennProductLoaded = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.lastPennProductLoaded
);

export const selectPacChangelogs = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pacChangelogs
);

export const selectPennProductsAvailable = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pennProductDefaultsList.length > 0
);

export const selectPennDynamicFieldsMasterData = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.dynamicFieldsMasterData?.penn
);

export const selectPennDynamicFieldsMasterDataReady = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => !!(state.dynamicFieldsMasterData?.penn.fields.length && state.dynamicFieldsMasterData?.penn.dealerAddOnValidations.length)
);

export const selectPennProviders = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pennProviders
);

export const selectPennProvidersLoadSuccess = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pennProvidersLoadSuccess
);

export const selectHasPennProviders = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.pennProviders?.length > 0
);

export const selectTaxCategories = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.taxCategories
);

export const selectOfferTypes = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.offerTypes
);

export const selectSalesClasses = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.salesClasses
);

export const selectTermDurations = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.termDurations
);

export const selectIsLoading = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.loading
);

export const selectBrands = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.brandTmisList
);

export const selectMasterDataReady = createSelector(
    selectProtectionProductsFeature, selectProductOfferings, selectOfferTypes, selectSalesClasses, selectTermDurations, selectPennDynamicFieldsMasterData, selectTaxCategories,
    (state: ProtectionProductsState, offerings: IProduct[], offerTypes: string[], vehicleTypes: string[], termDurations: ITermDuration[], dynamicFieldsMasterData: IFieldConfig, taxCategories: ITaxCategory[]) =>
        state.masterDataLoadCounter === 0 && !!(offerings && vehicleTypes?.length && termDurations?.length && offerTypes?.length
            && state.pennProvidersLoadSuccess && taxCategories.length
        )
);

export const selectMasterDataLoading = createSelector(
    selectProtectionProductsFeature,
    (state: ProtectionProductsState) => state.masterDataLoadCounter > 0
);

export const selectMasterDataLoadFailed = createSelector(
    selectProtectionProductsFeature, selectPennDynamicFieldsMasterDataReady,
    (state: ProtectionProductsState, dynamicFieldsMasterDataReady: boolean) => ((!state.productOfferingsNotFound && state.productOfferingsLoadFailed) || state.pennProvidersLoadFailed || state.taxCategoryLoadFailed || (!state.isSetDealer && !dynamicFieldsMasterDataReady)) && state.masterDataLoadCounter === 0
);
