import { IBankOffer } from "./../../shared/models/bank-configuration.model";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  IDealerConfiguration,
  IMarkups,
  IParameter,
} from "../../shared/models/dealer-configuration.model";
import { FeatureKeys } from "../app.reducer";
import { DealerConfigurationState } from "./dealer-configuration.reducer";
import { IRangeByOffer } from "../../pe-admin/dealer-defined-campaigns/models/range-by-offer.model";
import { OfferTypes } from "../../shared/enums/offer-types.enum";
import { IValueRange } from "../../shared/models/utility/value-range.model";

export const selectDealerConfigurationFeature = createFeatureSelector<
  DealerConfigurationState
>(FeatureKeys.DEALERCONFIGURATION);

export const selectDealerConfiguration = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerConfiguration
);

export const selectDealerConfigurationMarkup = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) =>
    state.dealerConfiguration?.policy?.global?.financeSource[0]?.markups
);

export const selectDealerConfigurationParameters = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) =>
    state.dealerConfiguration?.policy?.global?.parameters
);

export const selectIsLoading = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.loading
);
export const selectSystemConfigLoaded = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.systemConfigLoaded
);

export const selectIsLoadingBankConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.loadingBankConfig
);

export const selectIsLoadingSystemConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.loadingSystemConfig
);

export const selectOfferMarkupMasterDataReady = createSelector(
  selectDealerConfiguration,
  selectDealerConfigurationMarkup,
  (dealerConfiguration: IDealerConfiguration, markup: IMarkups[]) =>
    !!(dealerConfiguration && markup && markup.length)
);

export const selectDealerConfigurationReady = createSelector(
  selectDealerConfiguration,
  (dealerConfiguration: IDealerConfiguration) => !!dealerConfiguration
);

export const selectLeaseSettingsMasterDataReady = createSelector(
  selectDealerConfiguration,
  selectDealerConfigurationParameters,
  (dealerConfiguration: IDealerConfiguration, parameters: IParameter[]) =>
    !!(dealerConfiguration && parameters && parameters.length)
);

export const selectDealerFeatureConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerFeatureConfig
);

export const selectDealerFeatureConfigError = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerFeatureConfigError
);

export const selectCreditTier = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.creditTier
);

export const selectPaymentGridConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.paymentGrid
);

export const selectGrossProfitConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.grossProfit
);


export const selectOfferConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.offerConfig
);

export const selectCampaignOffer = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.campaignOffer
);

export const selectPaymentGridData = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) =>
    state.dealerConfiguration.paymentGridCustomization
);

export const selectMaxStateApr = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerConfiguration.maxStateApr
);

export const selectdealerDefinedOfferTerms = createSelector(
  selectDealerConfigurationFeature,
  selectOfferConfig,
  selectIsLoading,
  (
    state: DealerConfigurationState,
    offerConfig: IBankOffer,
    isLoading: boolean,
    searchVal: { offerType: string; salesClass: string }
  ) => {
    if (!isLoading && offerConfig) {
      const alldealerDefinedTerms = [];
      const bankConfigTerms: number[] =
        offerConfig[searchVal.offerType]?.term?.default || [];
      return [...new Set(bankConfigTerms.concat(alldealerDefinedTerms))].sort(
        (a, b) => a - b
      );
    } else {
      return [];
    }
  }
);

export const selectLenderList = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerConfiguration.lender
);




export const selectLeaseBaserate = createSelector(
  selectOfferConfig,
  (_state: IBankOffer): IValueRange =>
    ({ min: 0, max: 0.2 })
  // _state?.lease?.baseRate?.standard || { min: 0, max: 0.2 }
);

export const selectMarkupRange = createSelector(
  selectOfferConfig,
  (_state: IBankOffer): IRangeByOffer[] => [
    {
      offerType: OfferTypes.FINANCE,
      min: 0, max: 3
      // ...(_state?.finance?.markup?.standard || { min: 0, max: 3 }),
    },
    {
      offerType: OfferTypes.LEASE,
      min: 0, max: 0.02
      // ...(_state?.lease?.markup?.standard || { min: 0, max: 0.02 }),
    },
  ]
);
export const selectOfferTypesdealerConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.offerTypes
);

export const selectSalesClassesdealerConfig = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.salesClasses
);
export const selectDisableActions = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => !state.isDraftState
);
export const selectDraftStatus = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.isDraftState
);

export const selectPpCompatibility = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.ppCompatibility
);

export const selectBankConfigLoaded = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.bankConfigLoaded
);

export const selectpaymentGridRange = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.paymentGrid.downPaymentRange
);

export const selectvehiclePac = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.dealerConfiguration.vehiclePac
);

export const selectBrands = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.brandList
);

export const selectVehiclePacLimits = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.vehiclePacLimits
);

export const selectPpPacLimits = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.protectionProductPacLimits
);

export const selectBankAndSystemConfigLoaded = createSelector(
  selectDealerConfigurationFeature,
  (state: DealerConfigurationState) => state.bankConfigLoaded && state.systemConfigLoaded
);