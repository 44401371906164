import { ProvidersEnum } from '../../../shared/enums/provider-id.enum';
import { ProtectionProductsComponentService } from './../protection-products.component.service';
import { AbstractControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IProduct } from '../../../shared/models/product-offerings.model';

@Pipe({
    name: 'productsmasterdatafilter'
})
export class ProductsMasterDataFilterPipe implements PipeTransform {

    constructor(private readonly pPComponentService: ProtectionProductsComponentService) { }

    transform(productId: string): IProduct {
        return _.find(this.pPComponentService.productOfferings, (t: IProduct) => t.id === productId);
    }
}
