<div [formGroup]="pPComponentService.protectionProductsForm"
    class="d-flex flex-column product-list pos-relative tfs-pp-color">
    <span *ngIf="isOwnPpType" class="title-span mb-3">{{('LOCALIZATION.PROTECTION_PRODUCTS.'
        + (pPComponentService.isSetDealer ? 'HEADER_NON_TFS' : 'HEADER_OPTION_TOYOTA')) | translate}}
        {{'LOCALIZATION.PROTECTION_PRODUCTS.HEADER_APPEND_PP' |
        translate}}
    </span>

    <div *ngIf="isOwnPpType && pPComponentService.productOfferings && pPComponentService.productOfferings.length > 1"
        class="pos-absolute ordering-div">
        <input *ngIf="!pPComponentService.isReadOnly && (pPComponentService.getChangeOrder$ | async)" type="button"
            class="secondary-button small-button ml-3" (click)="editOrderAction(false); pPComponentService.resetForm()"
            value="{{'LOCALIZATION.COMMON.CANCEL' | translate}}" />
        <input
            *ngIf="!pPComponentService.isReadOnly && !((pPComponentService.getAddingProduct$ | async) || (pPComponentService.getChangeOrder$ | async))"
            type="button" class="primary-button small-button ml-3" (click)="editOrderAction(true)"
            value="{{'LOCALIZATION.PROTECTION_PRODUCTS.EDIT_ORDER' | translate}}" />
        <input *ngIf="(pPComponentService.getChangeOrder$ | async) && !pPComponentService.isReadOnly" type="button"
            class="primary-button small-button ml-3" (click)="updateOrder()"
            value="{{'LOCALIZATION.PROTECTION_PRODUCTS.SAVE_ORDER' | translate}}" />
    </div>
    <input *ngIf="!pPComponentService.isReadOnly && !isOwnPpType && !(pPComponentService.getEditDisabled$ | async)"
        type="button" class="primary-button small-button ml-3 add-product-btn" (click)="addProductAction()"
        [disabled]="!(hasPennProviders$ | async)"
        value="{{'LOCALIZATION.PROTECTION_PRODUCTS.ADD_PRODUCT' | translate}}" />
    <div class="d-flex flex-column">
        <span
            *ngIf="pPComponentService.isSetDealer && isOwnPpType && pPComponentService.productOfferings && pPComponentService.productOfferings.length"
            class="mb-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.MAX_PROD_TEXT' | translate}}</span>
        <mat-select (selectionChange)="updateMaxVisibilePPConfirmation($event.value)"
            *ngIf="pPComponentService.isSetDealer && isOwnPpType && pPComponentService.productOfferings.length"
            class="mb-2" formControlName="maxVisiblePP">
            <mat-option *ngFor="let val of pPComponentService.productOfferings, let i = index" [value]="i+1">
                {{i+1}}</mat-option>
        </mat-select>
        <span class="mb-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.HEADER_NOTE' | translate}}</span>
    </div>
    <div class="d-flex flex-column products-table-list" (cdkDropListDropped)="drop($event)" cdkDropList
        [cdkDropListDisabled]="!(pPComponentService.getChangeOrder$ | async) || (disableActions$ | async)">
        <table class="table example-full-width" aria-describedby="tableHd">
            <thead>
                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PRODUCT' | translate}}</th>
                <th scope="col" *ngIf="!isOwnPpType" class="pl-2">
                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_PROVIDER' |
                    translate}}</th>
                <th scope="col" class="pl-2 minw-145px whitespace-no-wrap">
                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_GP' |
                    translate}}</th>
                <th scope="col"
                    *ngIf="pPComponentService.isTfsDealer || (pPComponentService.isSetDealer && !isOwnPpType)"
                    class="pl-2 minw-85px whitespace-no-wrap">
                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_MP' | translate}}<em *ngIf="!isOwnPpType"
                        class="max-price-info tmi tmi-info ml-1 p-0"
                        matTooltip="{{'LOCALIZATION.PROTECTION_PRODUCTS.MAX_PRICE_TOOLTIP' | translate}}"></em>
                </th>
                <!-- <th scope="col" *ngIf="pPComponentService.isSetDealer && isOwnPpType" class="pl-2 whitespace-no-wrap">
                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_TAX' | translate}}</th> -->
                <th scope="col" class="pl-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_CAPITALIZED' | translate}}
                </th>
                <th scope="col" class="pl-2">
                    {{'LOCALIZATION.PROTECTION_PRODUCTS.TABLE_HEAD_REGULATED' | translate}}</th>
                <th scope="col" class="pl-2"></th>
            </thead>
            <tbody formArrayName="products">
                <ng-container *ngFor="let itemrow of (pPComponentService.getOrderChangeDetector$ | async)
                | productsfilter : isOwnPpType; let i=index, last as lastRow">
                    <tr *ngIf="itemrow.getRawValue() as itemrowvalue"
                        [ngClass]="{'selected-product': itemrowvalue.id === (pPComponentService.getSelectedProduct$ | async)?.id}"
                        (click)="setProductSelection(itemrowvalue.id)" class="border-bottom"
                        [formGroupName]="isOwnPpType ? i :  i + pPComponentService.ownPpCount" cdkDrag>
                        <mat-label *cdkDragPreview style="background: white; padding: 5px; border-radius: 5px;">
                            <div class="tmi icon tmi-move">
                                <span class="product-type">
                                    {{i+1}}.
                                    {{ !isOwnPpType ? itemrowvalue.global?.productTitle || 'No Title Set' :
                                    (itemrowvalue.id | productdescription)
                                    }}
                                </span>
                            </div>
                        </mat-label>
                        <td cdkDragHandle [ngClass]="{'cursor-move': (pPComponentService.getChangeOrder$ | async)}">
                            <mat-label>
                                <div [ngClass]="{'tmi icon tmi-move': (pPComponentService.getChangeOrder$ | async)}">
                                    <mat-icon *ngIf="itemrowvalue.global.isNotConfigured"
                                        matTooltip="{{'LOCALIZATION.PROTECTION_PRODUCTS.PRODUCT_NOT_CONFIGURED' | translate}}"
                                        class="unconfigured-product">warning</mat-icon>
                                    <span class="product-type">
                                        {{i+1}}.
                                        {{ !isOwnPpType ? itemrowvalue.global?.productTitle || 'No Title Set' :
                                        (itemrowvalue.id | productdescription)
                                        }}
                                    </span>
                                    <em *ngIf="itemrowvalue | hasexcludedplans"
                                        class="excluded-plans-info tmi tmi-info p-0"
                                        matTooltip="{{'LOCALIZATION.PROTECTION_PRODUCTS.HAS_EXCLUDED_PLANS_TOOLTIP' | translate}}"></em>
                                </div>
                            </mat-label>
                        </td>
                        <td *ngIf="!isOwnPpType">
                            <mat-label>
                                <span class="product-type"> {{itemrowvalue.global.provider || ''}}</span>
                            </mat-label>
                        </td>
                        <td formGroupName="global">
                            <div formGroupName="gp">
                                <input type="number" numbersOnly min="0" class="product-input no-number-arrow"
                                    [matTooltip]="itemrow.controls.global.controls.gp.errors?.gpExceedsMaxPrice ? itemrow.controls.global.controls.gp.errors?.gpExceedsMaxPriceMsg : ''"
                                    [ngClass]="{'input-error': itemrow.controls.global.controls.gp.controls.value.invalid || itemrow.controls.global.controls.gp.errors?.gpExceedsMaxPrice,
                                'pointer-events-none': itemrowvalue.id !== (pPComponentService.getSelectedProduct$ | async)?.id }"
                                    formControlName="value" (click)="$event.stopPropagation();"
                                    [attr.disabled]="((disableActions$ | async) || (itemrowvalue.id | productsmasterdatafilter)?.isRegulated === 'Y') ? true : null">
                                <mat-button-toggle-group class="product-button-toggle"
                                    [ngClass]="{'pointer-events-none': itemrowvalue.id !== (pPComponentService.getSelectedProduct$ | async)?.id }"
                                    formControlName="type" [disabled]="(disableActions$ | async)">
                                    <mat-button-toggle (click)="$event.stopPropagation();"
                                        [value]="grossProfitTypes.AMOUNT" aria-label="Text align left"> $
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="$event.stopPropagation();"
                                        [value]="grossProfitTypes.PERCENT" aria-label="Text align center"> %
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </td>
                        <td *ngIf="pPComponentService.isTfsDealer || (pPComponentService.isSetDealer && !isOwnPpType)"
                            formGroupName="global">
                            <div class="pos-relative">
                                <span class="dollar-symbol">$&nbsp;</span>
                                <input type="number" numbersOnly min="0"
                                    class="product-input dollar-input no-number-arrow"
                                    [matTooltip]="itemrow.controls.global.controls.gp.errors?.gpExceedsMaxPrice ? itemrow.controls.global.controls.gp.errors?.gpExceedsMaxPriceMsg : ''"
                                    [ngClass]="{'input-error': itemrow.controls.global.controls.maxPrice.invalid,
                                'pointer-events-none': itemrowvalue.id !== (pPComponentService.getSelectedProduct$ | async)?.id}"
                                    formControlName="maxPrice" (click)="$event.stopPropagation();"
                                    [attr.disabled]="((disableActions$ | async) || (itemrowvalue.id | productsmasterdatafilter)?.isRegulated === 'Y') ? true : null">
                            </div>
                        </td>
                        <!-- <td formGroupName="global" *ngIf="pPComponentService.isSetDealer && isOwnPpType"
                            class="toggle-row-ed">
                            <mat-slide-toggle formControlName="taxable"
                                [ngClass]="{'pointer-events-none': itemrowvalue.id !== (pPComponentService.getSelectedProduct$ | async)?.id }"
                                (click)="$event.stopPropagation();" [disabled]="(disableActions$ | async)">
                            </mat-slide-toggle>
                        </td> -->
                        <td formGroupName="global" class="toggle-row-ed">
                            <mat-slide-toggle formControlName="capitalized"
                                [ngClass]="{'pointer-events-none': itemrowvalue.id !== (pPComponentService.getSelectedProduct$ | async)?.id }"
                                (click)="$event.stopPropagation();" [disabled]="(disableActions$ | async)">
                            </mat-slide-toggle>
                        </td>
                        <td>
                            <mat-label>{{(itemrowvalue.id | productsmasterdatafilter)?.isRegulated === 'Y' ? 'Yes' :
                                'No'}}</mat-label>
                        </td>
                        <td>
                            <mat-label><em class="tmi icon tmi-arrow-half-right"></em></mat-label>
                        </td>
                    </tr>
                </ng-container>
                <tr
                    *ngIf="!((pPComponentService.getOrderChangeDetector$ | async) | productsfilter : isOwnPpType).length">
                    <mat-label class="d-flex pt-2">{{'LOCALIZATION.PROTECTION_PRODUCTS.NO_PRODUCTS' | translate}}
                    </mat-label>
                </tr>
            </tbody>
        </table>
    </div>
</div>