import { UserProfileService } from "@toyota/dd365-platform-library";
import { Injectable } from "@angular/core";
import { IDealerFeatureConfig } from "../models/rti/rti-feature-config.model";
import { FinanceSources } from "../../../models/enums";
import _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class DealerInfoService {
  borderSetForStart: boolean;
  borderSetForEnd: boolean;
  offerFlag;
  myIndex: any;
  myInput: any;
  myResiduals: any;
  offerTypeEnabledValue;
  borderForMarkUp: boolean;
  borderForRateRCF: boolean;

  private readonly PAYMENT_ENGINE_FEATURE_NAME =
    "PaymentEngineAdminUI-DealerConfig";
  readonly protectionProductsFeatureName = "ProtectionProducts";
  readonly rateAndResidualFeatureName = "DealerDefinedRatesAndResiduals";
  private _isPPFeatureEnabled: boolean = false;
  _isDealerConfigNotFound: boolean = false;

  constructor(private readonly _userProfileService: UserProfileService) { }

  getDealerDistributor() {
    const regionCode = +this._userProfileService.getProfile().dealer.region
      .code;
    let distributor;
    if (
      regionCode >= FinanceSources.MIN_SET &&
      regionCode <= FinanceSources.MAX_SET
    ) {
      distributor = FinanceSources.SET_ORG;
    } else if (
      regionCode >= FinanceSources.MIN_GST &&
      regionCode <= FinanceSources.MAX_GST
    ) {
      distributor = FinanceSources.GST_ORG;
    } else if (
      (regionCode >= FinanceSources.MIN_TMNA &&
        regionCode <= FinanceSources.MAX_TMNA) ||
      (regionCode >= FinanceSources.MIN_LEXUS &&
        regionCode <= FinanceSources.MAX_LEXUS) ||
      regionCode === FinanceSources.TMNA_ADDITIONAL_REGION
    ) {
      distributor = FinanceSources.TMNA_ORG;
    }
    return distributor;
  }

  getDealerBankDistributor() {
    const regionCode = +this._userProfileService.getProfile().dealer.region
      .code;
    let bankDistributor;
    if (
      regionCode >= FinanceSources.MIN_SET &&
      regionCode <= FinanceSources.MAX_SET
    ) {
      bankDistributor = FinanceSources.SET;
    } else if (
      regionCode >= FinanceSources.MIN_GST &&
      regionCode <= FinanceSources.MAX_GST
    ) {
      bankDistributor = FinanceSources.GST;
    } else if (
      (regionCode >= FinanceSources.MIN_TMNA &&
        regionCode <= FinanceSources.MAX_TMNA) ||
      regionCode === FinanceSources.TMNA_ADDITIONAL_REGION
    ) {
      bankDistributor = FinanceSources.TMNA;
    } else if (
      regionCode >= FinanceSources.MIN_LEXUS &&
      regionCode <= FinanceSources.MAX_LEXUS
    ) {
      bankDistributor = FinanceSources.LEXUS;
    }
    return bankDistributor;
  }

  isSETDealer() {
    return this.getDealerDistributor() === FinanceSources.SET_ORG;
  }

  isTFSDealer() {
    return this.getDealerDistributor() === FinanceSources.TMNA_ORG;
  }

  isGSTDealer() {
    return this.getDealerDistributor() === FinanceSources.GST_ORG;
  }

  setupFeatures(dealerFeatureConfig: IDealerFeatureConfig[]): boolean {
    if (dealerFeatureConfig) {
      this._isPPFeatureEnabled = this.isFeatureEnabledInMasterOverride(
        this.protectionProductsFeatureName,
        dealerFeatureConfig.find(
          (t) => t.masterFeature.name === this.PAYMENT_ENGINE_FEATURE_NAME
        )
      );
      return (
        dealerFeatureConfig.filter(
          (t) =>
            t.masterFeature.name === this.PAYMENT_ENGINE_FEATURE_NAME &&
            t.masterFeature.active
        ).length > 0
      );
    } else {
      return false;
    }
  }

  get isPPFeatureEnabled() {
    return this._isPPFeatureEnabled;
  }

  get isDealerConfigNotFound() {
    return this._isDealerConfigNotFound;
  }

  isFeatureEnabledInMasterOverride(featureName: string, dealerFeatureConfig: IDealerFeatureConfig): boolean {
    if (dealerFeatureConfig) {
      return !!(dealerFeatureConfig.masterFeature.active
        && (
          dealerFeatureConfig.overridenFeatureAttributes.find(t =>
            t.masterFeatureAttribute.name === featureName && t.masterFeatureAttribute.active && t.value == 'true')
          ||
          dealerFeatureConfig.masterFeature.attributes.find(t => t.name === featureName && t.active && t.defaultValue === 'true')

        )
      )
    }
    return false;
  }

  isFeatureEnabled(dealerFeatureConfig: IDealerFeatureConfig): boolean {
    return dealerFeatureConfig?.masterFeature.active;
  }
}
