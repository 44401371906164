import { IPacChangelog, IGetPennProductDefaults } from './../../shared/models/protection-products.model';
import { IDynamicFieldsMasterData, IPennProductDefaults, IProtectionProducts, ITaxCategory, ITermDuration } from '../../shared/models/protection-products.model';
import produce from 'immer';
import * as fromActions from './protection-products.actions';
import { IPennProvider, IProduct } from '../../shared/models/product-offerings.model';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { OfferTypes } from '../../shared/enums/offer-types.enum';
import { updatePennProductDefaultsList, updateTfsProductsOrderSuccess, updateTfsProductsSuccess } from './protection-products.adapter';
import { brandTmisList } from '../../../mocks/brands';
import { IBrand } from '../../shared/models/brand.model';
export interface ProtectionProductsState {
    protectionProducts: IProtectionProducts;
    productOfferings: IProduct[];
    dynamicFieldsMasterData: IDynamicFieldsMasterData;
    termDurations: ITermDuration[];
    pennProviders: IPennProvider[];
    pennProductDefaultsList: IPennProductDefaults[];
    taxCategories: ITaxCategory[];
    pacChangelogs: IPacChangelog[];
    salesClasses: string[];
    offerTypes: string[];
    isSetDealer: boolean;
    isTfsDealer: boolean;
    loading: boolean;
    error: string;
    lastPennProductLoaded: IGetPennProductDefaults;
    pennProvidersLoadFailed: boolean;
    pennProvidersLoadSuccess: boolean;
    productOfferingsNotFound: boolean;
    productOfferingsLoadFailed: boolean;
    taxCategoryLoadFailed: boolean;
    masterDataLoadCounter: number;
    brandTmisList: IBrand[];
}

export const protectionProductsInitialState: ProtectionProductsState = {
    protectionProducts: { maxVisiblePP: 0, products: [] },
    productOfferings: [],
    pennProviders: [],
    pennProductDefaultsList: [],
    taxCategories: [],
    pacChangelogs: [],
    dynamicFieldsMasterData: { penn: { fields: [], dealerAddOnValidations: [] } },
    salesClasses: [SalesClasses.NEW, SalesClasses.CERTIFIED, SalesClasses.USED],
    offerTypes: [OfferTypes.LEASE, OfferTypes.FINANCE, OfferTypes.CASH],
    termDurations: [
        { minTerm: 0, maxTerm: 24 }, { minTerm: 25, maxTerm: 36 }, { minTerm: 37, maxTerm: 48 },
        { minTerm: 49, maxTerm: 60 }, { minTerm: 61, maxTerm: 72 }, { minTerm: 73, maxTerm: 0 }
    ],
    isTfsDealer: false,
    isSetDealer: false,
    loading: false,
    error: '',
    pennProvidersLoadFailed: false,
    pennProvidersLoadSuccess: false,
    lastPennProductLoaded: {} as IGetPennProductDefaults,
    productOfferingsNotFound: false,
    productOfferingsLoadFailed: false,
    taxCategoryLoadFailed: false,
    masterDataLoadCounter: 0,
    brandTmisList: brandTmisList
};

export function protectionProductsReducer(
    state: ProtectionProductsState = protectionProductsInitialState,
    action: fromActions.ProtectionProductsActions
): ProtectionProductsState {

    switch (action.type) {
        case fromActions.ProtectionProductsActionTypes.RESET_MASTER_DATA_LOAD_FAILED_FLAG: {
            return produce(state, draftState => {
                draftState.pennProvidersLoadFailed = false;
                draftState.productOfferingsLoadFailed = false;
                draftState.taxCategoryLoadFailed = false;
            });
        }
        case fromActions.ProtectionProductsActionTypes.RESET_AND_REBUILD_PP: {
            return produce(state, draftState => {
                draftState.productOfferings = [...state.productOfferings];
                draftState.protectionProducts.products = [...state.protectionProducts.products];
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS: {
            return produce(state, draftState => {
                draftState.productOfferings = [];
                draftState.protectionProducts = { maxVisiblePP: 0, products: [] };
                draftState.dynamicFieldsMasterData = { penn: { fields: [], dealerAddOnValidations: [] } };
                draftState.loading = true;
                draftState.productOfferingsLoadFailed = false;
                draftState.productOfferingsNotFound = false;
                draftState.masterDataLoadCounter++;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS_SUCCESS: {
            return produce(state, draftState => {
                draftState.productOfferings = action instanceof fromActions.LoadProductOfferingsSuccess ? action.payload.productDetails.masterData : [];
                draftState.protectionProducts = action instanceof fromActions.LoadProductOfferingsSuccess ? action.payload.productDetails.configurationData
                    : { maxVisiblePP: 0, products: [] };
                draftState.dynamicFieldsMasterData = action instanceof fromActions.LoadProductOfferingsSuccess ? action.payload.productDetails.dynamicFields
                    : { penn: { fields: [], dealerAddOnValidations: [] } };
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PRODUCT_OFFERINGS_FAILURE: {
            return produce(state, draftState => {
                draftState.productOfferings = [];
                draftState.protectionProducts = { maxVisiblePP: 0, products: [] };
                draftState.dynamicFieldsMasterData = { penn: { fields: [], dealerAddOnValidations: [] } };
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.productOfferingsLoadFailed = true;
                draftState.error = action instanceof fromActions.LoadProductOfferingsFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.PRODUCT_OFFERINGS_NOT_FOUND: {
            return produce(state, draftState => {
                draftState.productOfferings = [];
                draftState.protectionProducts = { maxVisiblePP: 0, products: [] };
                draftState.dynamicFieldsMasterData = action.payload.dynamicFields || { penn: { fields: [], dealerAddOnValidations: [] } };
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.productOfferingsNotFound = true;
                draftState.productOfferingsLoadFailed = true;
                draftState.error = action.payload.error || '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS: {
            return produce(state, draftState => {
                draftState.pennProviders = [];
                draftState.loading = true;
                draftState.pennProvidersLoadFailed = false;
                draftState.pennProvidersLoadSuccess = false;
                draftState.masterDataLoadCounter++;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS_SUCCESS: {
            return produce(state, draftState => {
                draftState.pennProviders = action instanceof fromActions.LoadPennProvidersSuccess ? action.payload.pennProviders : [];
                draftState.pennProvidersLoadSuccess = true;
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PROVIDERS_FAILURE: {
            return produce(state, draftState => {
                draftState.pennProviders = [];
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.pennProvidersLoadFailed = true;
                draftState.error = action instanceof fromActions.LoadPennProvidersFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS: {
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.pennProductDefaultsList = action instanceof fromActions.LoadPennProductDefaultsSuccess ?
                    updatePennProductDefaultsList(state, action.payload.pennProducts) : state.pennProductDefaultsList;
                draftState.lastPennProductLoaded = action.payload.pennProducts;
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.RESET_LAST_PENN_PRODUCT_LOADED: {
            return produce(state, draftState => {
                draftState.lastPennProductLoaded = action.payload.pennProducts;
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PENN_PRODUCT_DEFAULTS_FAILURE: {
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.error = action instanceof fromActions.LoadPennProductDefaultsFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES: {
            return produce(state, draftState => {
                draftState.taxCategories = [];
                draftState.loading = true;
                draftState.taxCategoryLoadFailed = false;
                draftState.masterDataLoadCounter++;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES_SUCCESS: {
            return produce(state, draftState => {
                draftState.taxCategories = action instanceof fromActions.LoadTaxCategoriesSuccess ?
                    action.payload.taxCategories : [];
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_TAX_CATEGORIES_FAILURE: {
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.masterDataLoadCounter--;
                draftState.taxCategoryLoadFailed = true;
                draftState.error = action instanceof fromActions.LoadTaxCategoriesFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS: {
            return produce(state, draftState => {
                draftState.pacChangelogs = [];
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS_SUCCESS: {
            return produce(state, draftState => {
                draftState.pacChangelogs = action instanceof fromActions.LoadPacChangelogsSuccess ?
                    action.payload.pacChangelogs : [];
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.LOAD_PAC_CHANGELOGS_FAILURE: {
            return produce(state, draftState => {
                draftState.pacChangelogs = [];
                draftState.loading = false;
                draftState.error = action instanceof fromActions.LoadPacChangelogsFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdateProtectionProducts ? action.payload : null;
                draftState.protectionProducts = payload.protectionProducts;
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdateProtectionProductsSuccess ? action.payload : null;
                draftState.protectionProducts = payload.protectionProducts;
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_PROTECTION_PRODUCTS_FAILURE: {
            return produce(state, draftState => {
                draftState.protectionProducts = state.protectionProducts;
                draftState.loading = false;
                draftState.error = action instanceof fromActions.UpdateProtectionProductsFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS:
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER:
        case fromActions.ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP: {
            return produce(state, draftState => {
                draftState.loading = true;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdateTfsProtectionProductsSuccess ? action.payload : null;
                updateTfsProductsSuccess(state, draftState, payload?.protectionProducts);
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PROTECTION_PRODUCTS_FAILURE: {
            return produce(state, draftState => {
                draftState.protectionProducts = state.protectionProducts;
                draftState.loading = false;
                draftState.error = action instanceof fromActions.UpdateTfsProtectionProductsFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdateTfsProductsOrderSuccess ? action.payload : null;
                updateTfsProductsOrderSuccess(state, draftState, payload?.protectionProducts);
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_TFS_PRODUCTS_ORDER_FAILURE: {
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.error = action instanceof fromActions.UpdateTfsProductsOrderFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP_SUCCESS: {
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.error = '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_MAX_VISIBLE_PP_FAILURE: {
            return produce(state, draftState => {
                draftState.loading = false;
                draftState.error = action instanceof fromActions.UpdateMaxVisiblePPFailure ? action.payload.error : '';
            });
        }
        case fromActions.ProtectionProductsActionTypes.UPDATE_ISSETDEALER: {
            return produce(state, draftState => {
                draftState.isSetDealer = action.payload.isSetDealer;
            });
        }
        default:
            return state;
    }

}
